<template>
  <div class="block-products">
    <n-divide class="table-tools">
      <div>
        <template v-if="editable && order.status === 'calculation'">
          <n-button v-if="!checkedProducts" @click="checkedProducts = []">Сформировать запрос поставщику</n-button>
          <n-button v-if="checkedProducts && !$n.size(checkedProducts)" color="primary" disabled>Выберете товары для отправки поставщику</n-button>
          <n-button v-if="$n.size(checkedProducts)" color="primary" @click="chooseProvider = true">К выбору поставщиков</n-button>
          <n-button v-if="checkedProducts" @click="checkedProducts = undefined">Отменить</n-button>
        </template>
      </div>
      <div v-if="editable">
        <n-button @click="productId = -1">Добавить товар</n-button>
        <n-button @click="$var('fromExcel', true)">Загрузить товары из Excel</n-button>
      </div>
    </n-divide>
    <n-table :data="products" :columns="columns" :loading="$var('load')">
      <template #checkbox="{item}">
        <input v-model="checkedProducts" type="checkbox" :value="item.id" />
      </template>
      <template #description="{item}">
        <div v-html="$app.secure.clean(item.description)" />
      </template>
      <template #image="{item}">
        <n-image class="product-image" :src="item.image" />
      </template>
      <template #total="{item}">
        {{ (item.total && item.quantity) ? $n.numberFormat(item.total / item.quantity) : '-' }}
        {{ (item.calculation) ? item.calculation.currency2 : '' }}
      </template>
      <template #tools="{item}">
        <n-button round icon="eye" @click="productId = item.id" />
      </template>
    </n-table>

    <load-excel v-if="$var('fromExcel')" :order-id="order.id" @close="$var('fromExcel', false)" @reload="load" />

    <modal-product v-if="productId" :order="order" :editable="editable" @close="productId = undefined" @reload="$emit('reload')" />
    <modal-providers v-if="chooseProvider" :order="order" @close="chooseProvider = undefined" @reload="$emit('reload')" />
  </div>
</template>

<script>
import ModalProduct from './../product/Index'
import ModalProviders from './../providers/Index'
import LoadExcel from './LoadExcel'

export default {
  name: 'BlockProducts',
  components: { LoadExcel, ModalProduct, ModalProviders, },
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      s_productId: undefined,
      products: [],
    }
  },
  computed: {
    productId: {
      get() {
        return this.s_productId
      },
      set(value) {
        this.s_productId = value === undefined ? undefined : value * 1
        if (value !== this.$route.query['order-product']) {
          this.$router.push({ query: { ...this.$route.query, 'order-product': value, }, })
        }
      },
    },
    columns() {
      const result = []
      const columns = [
        { name: 'edit', title: '', },
        { name: 'number', title: '№', },
        { name: 'title', title: 'Название товара', },
        { name: 'image', title: 'Фото', },
        { name: 'code', title: 'Stock Code', },
        { name: 'description', title: 'Описание', },
        { name: 'quantity', title: 'Кол-во', },
        { name: 'unit', title: 'Ед. измерения', },
        { name: 'category.title', title: 'Категория', },
        { name: 'total', title: 'Цена для заказчика за ед в валюте КП', },
        { name: 'tools', title: '', },
      ]
      if (this.checkedProducts) {
        result.push({ name: 'checkbox', title: '', })
      }

      return [ ...result, ...columns, ]
    },
    checkedProducts: {
      get() {
        const data = this.$route.query.products
        if (data === '' || data === null) return []
        if (data === undefined) return null
        return data.split(',')
      },
      set(value) {
        this.$router.push({ query: {
          ...this.$route.query,
          products: value ? value.join(',') : value,
        }, })
      },
    },
    chooseProvider: {
      get() {
        return this.$route.query.providers
      },
      set(value) {
        this.$router.push({ query: {
          ...this.$route.query,
          providers: value,
        }, })
      },
    },
  },
  watch: {
    order() {
      this.load()
    },
  },
  created() {
    if (this.order.id) {
      this.load()
    }
    this.productId = this.$route.query['order-product']
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.products.get().with('category').with('calculation').filter({ applicationId: this.order.id, }).sort('number,asc').then((response) => {
        this.products = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block-products {
  padding: 30px 0;

  .table-tools {
    .n-button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .n-table {
    font-size: .9em;
  }
  .product-image {
    width: 50px;
    height: 50px;
    &::v-deep img { object-fit: cover; }
  }
}
</style>
