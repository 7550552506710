<template>
  <div class="c-info-table">
    <table>
      <tr v-for="(item, i) in data" :key="item.title">
        <td>{{ item.title }}</td>
        <td>
          <slot v-if="item.name" :name="item.name">{{ item.value }}</slot>
          <template v-else>{{ item.value }}</template>

          <div v-if="!i" class="tools">
            <slot />
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'CInfoTable',
  props: {
    data: { type: Array, default: () => [], },
  },
}
</script>

<style lang="scss" scoped>
.c-info-table {
  .tools {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    text-align: right;
  }
  table {
    width: 100%;
    tr:first-child td:last-child {
      position: relative;
      padding-right: 100px;
    }
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;

      &:first-child {
        width: 20%;
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
    }
  }
}
</style>
