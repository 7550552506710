<template>
  <div class="tab-offers">
    <n-loader :loading="$var('load')" />
    <div v-if="offers.length !== 0 || this.$var('load')" class="price-offers-table">
      <n-table :data="offers" :columns="columns">
        <template #number="{item}">
          <n-link :to="{ name: 'orders.index', params: {id: item.applicationId}, query: { page: 'products', 'order-product': item.product.id, tab: 'calc', }, }">
            {{ item.application.number }}
          </n-link>
        </template>
        <template #image="{item}">
          <n-image :src="item.image" centered />
        </template>
        <template #description="{item}">
          <div v-html="$app.secure.clean(item.product.description)" />
        </template>
        <template #price="{item}">
          {{ $n.numberFormat(item.price) }} {{ item.currency }}
        </template>
        <template #kpPrice="{item}">
          <span v-if="item.calculation">{{ $n.numberFormat(item.calculation.pieceCostCustomer) }} {{ item.calculation.commercialCurrency }}</span>
          <span v-else>-</span>
        </template>
        <template #status="{item}">
          {{ $n.status(item.application.status).title }}
        </template>
        <template #createdAt="{item}">
          {{ $app.date.format(item.createdAt) }}
        </template>
      </n-table>
    </div>
    <div v-else>
      <h3 class="empty-message">У поставщика нет Ценовых Предложений...</h3>
    </div>
    <n-modal v-if="$var('create')" @close="$var('create', false)">
      <div class="modal-container">
        <h3>
          Информация о рассчетах
        </h3>
      </div>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'TabOffers',
  data: () => ({
    page: 'offers',
    offers: [],
    columns: [
      { name: 'number', title: '№ Заявки', },
      { name: 'image', title: 'Фото', },
      { name: 'title', title: 'Название товара', },
      { name: 'weight', title: 'Габариты и вес товара', },
      { name: 'price', title: 'Стоимость', },
      { name: 'kpPrice', title: 'Стоимость в КП', },
      { name: 'address', title: 'Адрес отгрузки', },
      { name: 'status', title: 'Статус заявки', },
      { name: 'createdAt', title: 'Дата запроса', },
    ],
  }),
  computed: {
    id() {
      return this.$route.query.provider
    },
  },
  mounted() {
    this.loadProducts()
  },
  methods: {
    loadProducts() {
      this.$var('load', true)
      $api.app.priceOffers.get().filter({ supplierId: this.id, }).with('product').with('application').with('calculation').all().then((response) => {
        this.offers = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-offers {
  position: relative;
  table {
    width: 100%;
    .table-headers {
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
  }
  td {
    padding: 10px 15px;
    border-bottom: 2px solid #f6f6f6;
  }
  .select-button {
    & button {
      color: #02B302;
    }
  }
  .modal-container {
    text-align: center;
  }
  --n-image-width: 80px;
  --n-image-height: 80px;
}
</style>
