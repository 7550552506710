<template>
  <div class="tab-letters">
    <n-loader :loading="$var('load') && !loading" />
    <div v-if="$n.size(letters)" class="list">
      <div class="table-tools">
        <n-link :to="linkNewLetter"><n-button color="primary">Новое письмо</n-button></n-link>
      </div>
      <n-table :data="letters" :columns="columns">
        <template #subject="{item}">
          {{ item.subject }}
          <n-dropdown v-if="item.files.length" :data="item.files" item-title="name">
            <div><n-icon icon="file" /> {{ item.files.length }}</div>
          </n-dropdown>
        </template>
        <template #from="{item}">
          {{ item.fromEmail }}
        </template>
        <template #tools="{item}">
          <div class="tools">
            <n-button v-if="selected.id !== item.id" icon="eye" round @click="selected = item" />
          </div>
        </template>
      </n-table>
    </div>
    <div v-if="selected.id" class="card">
      <c-letter :editable="editable" :letter="selected" :loading="$var('load')" @delete="remove" />
    </div>
    <div v-if="!$n.size(letters) && !$var('load') && !loading" class="empty-message">
      <div style="margin-bottom: 10px">Писем нет</div>
      <n-link v-if="editable" :to="linkNewLetter"><n-button color="primary">Новое письмо</n-button></n-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LettersHistory',
  props: {
    order: { type: Object, default: () => ({}), },
    type: { type: String, default: 'customer', }, // customer or provider
    editable: { type: Boolean, default: false, },
    loading: { type: Boolean, default: true, },
    ownerId: { type: Number, default: 0, },
  },
  data() {
    return {
      letters: [],
      last: null,
      s_selected: {},
      columns: [
        { name: 'createdAt', title: 'Дата', },
        { name: 'subject', title: 'Тема письма', },
        { name: 'from', title: 'От', },
        { name: 'tools', title: '', width: '100px', },
      ],
    }
  },
  computed: {
    selectedId() {
      const id = this.$route.query.letter
      return id ? id*1 : 0
    },
    selected: {
      get() {
        return this.s_selected
      },
      set(value) {
        this.s_selected = value
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            letter: value.id,
          },
        })
      },
    },
    linkNewLetter() {
      return { name: 'inbox.create', query: { orderId: this.order.id, orderType: this.type, }, }
    },
  },
  watch: {
    order() {
      this.load()
    },
    '$route.query.page'() {
      this.letters = []
      this.selected = {}
      this.load()
    },
  },
  beforeDestroy() {
    if (this.selectedId) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          letter: undefined,
        },
      })
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      if (this.order.id) {
        this.$var('load', true)
        const types = { provider: 'supplier', customer: 'customer', }
        const apis = [
          $api.app.orders.letters(this.order.id).filter({ type: types[this.type], })
            .with('files').with('recipients').with('owner').sort('createdAt,desc').all(),
          $api.app.orders.letters(this.order.id).filter({ type: types[this.type], }).sort('createdAt', 'desc').size(1),
        ]
        Promise.all(apis).then((responses) => {
          this.letters = responses[0].data.content
          this.last = $n.get(responses[1], 'data.content.0')
          if (this.letters.length) {
            if (this.selectedId) {
              this.s_selected = $n.find(this.letters, [ 'id', this.selectedId, ])
            } else {
              this.selected = this.letters[0]
            }
          }
        }).finally(() => {
          this.$var('load', false)
        })
      } else if (this.ownerId !== 0) {
        this.$var('load', true)
        let apis = []
        if (this.type === 'customer') {
          apis = [
            $api.app.customers.getLetters(this.ownerId).with('files').with('recipients').with('owner').sort('createdAt,desc').all(),
            $api.app.customers.getLetters(this.ownerId).sort('createdAt', 'desc').size(1),
          ]
        } else {
          apis = [
            $api.app.providers.getLetters(this.ownerId).with('files').with('recipients').with('owner').sort('createdAt,desc').all(),
            $api.app.providers.getLetters(this.ownerId).sort('createdAt', 'desc').size(1),
          ]
        }
        Promise.all(apis).then((responses) => {
          this.letters = responses[0].data.content
          this.last = $n.get(responses[1], 'data.content.0')
          if (this.letters.length) {
            if (this.selectedId) {
              this.s_selected = $n.find(this.letters, [ 'id', this.selectedId, ])
            } else {
              this.selected = this.letters[0]
            }
          }
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    remove() {
      this.selected = {}
      this.load()
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-letters {
  padding-top: 20px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  min-height: 200px;
  .list {
    min-width: 400px;
  }
  .card {
    width: 100%;
    margin-left: 40px;
  }
}
</style>

