<template>
  <div class="block-fields">
    <n-loader :loading="$var('load')" />
    <h3>Дополнительная информация</h3>
    <div class="tools">
      <n-button v-if="editable" icon="plus" flat @click="openField(true)" />
    </div>
    <table>
      <tr v-for="item in fields" :key="item.id">
        <td>
          {{ item.title }}
          <n-button v-if="editable" icon="pen" flat @click="openField(item)" />
          <n-button v-if="editable" icon="trash" flat @click="remove(item.id)" />
        </td>
        <td>
          <div v-html="$app.secure.clean(item.content)"></div>
        </td>
      </tr>
    </table>

    <n-modal v-if="$var('field')" :loading="$var('loadModal')" @close="$var('field', false)">
      <n-form @submit="save">
        <n-items>
          <n-input title="Заголовок" v-bind="$form.input('title')" />
          <n-textarea title="Значение" v-bind="$form.input('content')" />
          <n-button type="submit" wide>Сохранить</n-button>
        </n-items>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'BlockFields',
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      fields: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.orders.fields.get(this.order.id).then((response) => {
        this.fields = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    openField(value) {
      this.$var('field', value)
      if (value === true) {
        this.$form.init({
          title: '',
          content: '',
        })
      } else if (value) {
        this.$form.init(value)
      }
    },
    save() {
      this.$var('loadModal', true)
      const data = this.$form.get()
      let api
      if (this.$var('field') === true) {
        api = $api.app.orders.fields.create(this.order.id, data)
      } else {
        api = $api.app.orders.fields.edit(this.order.id, this.$var('field').id, data)
      }
      api.then((response) => {
        this.load()
        this.$var('field', false)
      }).finally(() => {
        this.$var('loadModal', false)
      })
    },
    remove(id) {
      this.$var('loadModal', true)
      $api.app.orders.fields.delete(this.order.id, id).then((response) => {
        this.load()
      }).finally(() => {
        this.$var('loadModal', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block-fields {
  position: relative;
  .tools {
    position: absolute;
    top: 0;
    right: 0;
  }
  table {
    width: 100%;
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;

      &:first-child {
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
    }
  }
}
</style>
