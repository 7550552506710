<template>
  <div class="page-orders-index">
    <n-card :loading="$var('load')">
      <div class="order-info">
        <div>
          <div :class="['status', 'bg-'+$n.status(order.status).color, ]">{{ $n.status(order.status).title }}</div>
          <div class="title-block">
            <h2 class="title">Заявка: {{ order.number }}</h2>
            <div class="manager">
              <sub>Менеджер:</sub>&nbsp;
              <span v-if="order.manager">{{ order.manager.fullName }}</span>
              <span v-else>Не назначен</span>
            </div>
          </div>
          <sub class="date">{{ $app.date.format(order.createdAt) }}</sub>
        </div>
        <div class="tools">
          <block-statuses v-if="editable" :order="order" :loading="$var('load')" @update:loading="(v) => $var('load', v)" @reload="load" />
        </div>
      </div>

      <n-tabs :data="tabs" state name="page" />

      <n-tabs-content name="page">
        <template #info>
          <tab-info :order="order" :editable="editable" @reload="load" />
        </template>
        <template #products>
          <tab-products :order="order" :editable="editable" @reload="load" />
        </template>
        <template #customer>
          <c-letters-history :order="order" :loading="$var('load')" :editable="editable" />
        </template>
        <template #supplier>
          <c-letters-history :order="order" :loading="$var('load')" :editable="editable" type="provider" />
        </template>
      </n-tabs-content>
    </n-card>
    <n-card v-if="$app.auth.can('admin') && ['created', 'approving'].includes(order.status)" :loading="$var('loadDelete')" style="max-width: 1000px;">
      <h3 slot="header">Удаление заявки</h3>
      Осторожно, действие необратимо!
      <br />
      <n-button color="danger" style="margin-top: 10px; font-size: .7em" @click="deleteModel">Удалить заявку</n-button>
    </n-card>
  </div>
</template>

<script>
import TabInfo from './info/Index'
import TabProducts from './products/Index'
import BlockStatuses from './Statuses'

export default {
  name: 'PageOrdersIndex',
  components: { BlockStatuses, TabProducts, TabInfo, },
  data() {
    return {
      order: {},
      tabs: [
        { name: 'info', title: 'Информация о заявке', },
        { name: 'products', title: 'Товары', },
        { name: 'customer', title: 'Переписка с заказчиком', },
        { name: 'supplier', title: 'Переписка с поставщиками', },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    editable() {
      const me = $app.auth.user().id
      return $n.editableStatus(this.order.status) &&
          (this.order.managerId === me ||
              (this.order.authorId === me && this.order.managerId === null) ||
              $app.auth.can('admin'))
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const api = $api.app.orders.get(this.id)
        .with('products').with('customer').with('contact').with('manager')
        .with('documents').with('commercialOffer').with('data').with('fields').with('total')
      api.then((response) => {
        this.order = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    deleteModel() {
      this.$var('loadDelete', true)
      $api.app.orders.delete(this.order.id).then((response) => {
        this.$router.push({ name: 'orders.list', })
      }).finally(() => {
        this.$var('loadDelete', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-orders-index {
  .order-info {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .status {
      display: inline-block;
      padding: 3px 12px;
      font-size: .8em;
      margin-bottom: 5px;
    }

    .title-block {
      display: flex;
      align-items: baseline;
    }
    .title {
      margin: 0;
      font-size: 1.7em;
      font-weight: 700;
      line-height: 1;
      .status {
        display: inline-block;
        padding: 3px 12px;
        background: var(--success);
        color: var(--success-text);
        font-size: .8em;
        margin-bottom: 5px;
      }
    }
    .manager {
      margin-left: 20px;
    }
    .date {
      font-size: .8em;
    }
  }
}
</style>
