/**
 *
 */
function toDataURL(url, callback) {
  const xhr = new XMLHttpRequest()
  xhr.onload = function() {
    const reader = new FileReader()
    reader.onloadend = function() {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export default class UploadAdapter {
  send
  // The file loader instance to use during the upload.
  loader
  
  constructor(loader) {
    this.send = $api.files.create
    this.loader = loader
  }
  
  upload() {
    return this.loader.file.then((file) => new Promise((resolve, reject) => {
      this.send({ file, }).then((response) => {
        resolve({
          default: response.data.content.src,
        })
        // toDataURL(response.data.content.src, function(dataUrl) {
        //   console.log('RESULT:', dataUrl)
        //   resolve({
        //     default: dataUrl,
        //   })
        // })
      })
    }))
  }
  
  static uploader(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader)
    }
  }
}
