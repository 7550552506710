<template>
  <div class="c-contacts">
    <c-block-title :title="title">
      <n-button round icon="user-plus" @click="$var('contact', {})" />
    </c-block-title>

    <n-table :data="contacts" :columns="columns" :loading="$var('load')">
      <template #tools="{item}">
        <n-button icon="edit" round @click="$var('contact', item)" />
      </template>
      <template #delete="{item}">
        <n-button v-if="$app.auth.can('admin')" icon="trash" round @click="remove(item.id)" />
      </template>
    </n-table>

    <modal-contact v-if="$var('contact')" :contact="$var('contact')" :type="type" :model="model"
                   @reload="load" @close="$var('contact', false)" />
  </div>
</template>

<script>
import ModalContact from './ModalContact'

export default {
  name: 'CContacts',
  components: { ModalContact, },
  props: {
    title: { type: String, default: 'Контакты', },
    type: { type: String, required: true, }, // customer or provider
    model: { type: Object, default: () => ({}), },
  },
  data: () => ({
    contacts: [],
    columns: [
      { name: 'title', title: 'ФИО', },
      { name: 'email', title: 'Email', },
      { name: 'phone', title: 'Телефон', },
      { name: 'tools', title: '', },
      { name: 'delete', title: '', },
    ],
  }),
  watch: {
    model() {
      this.load()
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      if (this.model.id) {
        this.$var('load', true)
        $api.app[this.type+'s'].getContacts(this.model.id).all().then((response) => {
          this.contacts = response.data.content
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    remove(id) {
      this.$var('load', true)
      $api.app.contacts.delete(id).then(() => {
        this.load()
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.c-contacts {
  margin: 50px 0 50px 0;
  table {
    width: 100%;
    .table-headers {
      width: 20%;
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
  }
  td {
    padding: 10px 15px;
    border-bottom: 2px solid #f6f6f6;
  }
  .contacts-modal {
    --n-modal-width: 600px;
  }
  .header {
    display: flex;
    margin-bottom: 30px;
    align-items: center
  }
  .form {
    text-align: center;
    .buttons-container {
      display:flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 30px
    }
  }
}
</style>
