<template>
  <n-modal class="modal-offer" :loading="$var('load') || $var('loadSelects')" @close="$emit('close')">
    <n-form @submit="submit">
      <h3>Добавить ценовое предложение к заявке №{{ order.number }}</h3>
      <n-items>
        <div v-if="letter" class="letter">
          <div class="title" @click="$var('letterShow', !$var('letterShow'))">
            Письмо
            <n-icon :icon="$var('letterShow') ? 'angle-up' : 'angle-down'" />
          </div>
          <c-letter v-if="$var('letterShow')" :letter="letter" clear />
        </div>
        <n-input title="Название товара от поставщика *" v-bind="$form.input('title')" />
        <n-input v-if="product" title="Товар из заявки" :value="product" disabled />
        <n-select v-else title="Товар из заявки" :data="products" v-bind="$form.input('product', 'select')" />
        <n-input v-if="provider" title="Поставщик" :value="provider" disabled />
        <n-select v-else title="Поставщик *" :data="providers" v-bind="$form.input('supplier', 'select')" />
        <n-input title="Цена *" v-bind="$form.input('price')" type="number" />
        <n-select title="Валюта *" :data="currenciesTitles" v-bind="$form.input('currency', 'select')" />
        <upload-images title="Фото товара" v-bind="$form.input('image')" :editable="editable" />
        <n-input title="Наличие / Срок доставки" v-bind="$form.input('availability')" />
        <n-input title="Габариты и вес товара" v-bind="$form.input('weight')" />
        <n-input title="Адрес отгрузки" v-bind="$form.input('shippingAddress')" />
        <n-textarea title="Описание" simple v-bind="$form.input('description')" />
      </n-items>
      <n-divide style="margin-top: 30px">
        <div><n-button @click="$emit('close')">Отменить</n-button></div>
        <div><n-button v-if="editable" type="submit" color="success">Сохранить</n-button></div>
      </n-divide>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalOffer',
  props: {
    orderId: { type: Number, default: 0, },
    product: { type: Object, default: null, },
    provider: { type: Object, default: null, },
    letter: { type: Object, default: () => null, },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      order: {},
      offer: {},
      products: [],
      providers: [],
      currencies: [],
    }
  },
  computed: {
    offerId() {
      return this.$route.query.offer
    },
    currenciesTitles() {
      return this.currencies.map((i) => i.title)
    },
  },
  created() {
    this.load()
    this.loadData()
    this.loadSelects()
    this.$form.init({
      applicationId: this.orderId,
      product: this.product,
      supplier: this.provider,
      image: null,
      title: '',
      price: '',
      currency: '',
      availability: '',
      weight: '',
      shippingAddress: '',
      description: '',
    })
    this.$form.rules({
      title: [ 'required', ],
      product: [ 'required', ],
      supplier: [ 'required', ],
      price: [ 'required', ],
      currency: [ 'required', ],
    })
  },
  methods: {
    load() {
      if (this.offerId > 0) {
        this.$var('load', true)
        $api.app.offers.get(this.offerId).with('product').with('supplier').then((response) => {
          this.offer = response.data.content
          this.$form.init(this.offer)
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    loadData() {
      this.$var('loadData', true)
      $api.app.currencies.get().then((response) => {
        this.currencies = response.data.content
      }).finally(() => {
        this.$var('loadData', false)
      })
    },
    loadSelects() {
      this.$var('loadSelects', true)
      const apis = [
        $api.app.orders.get(this.orderId),
        $api.app.providers.get(),
      ]
      if (!this.product) {
        apis.push($api.app.products.get().filter({ applicationId: this.orderId, }))
      }
      Promise.all(apis).then((responses) => {
        this.order = responses[0].data.content
        this.providers = responses[1].data.content
        this.products = $n.get(responses, '2.data.content', [])
      }).finally(() => {
        this.$var('loadSelects', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const file = this.$form.get('image')

        if ($n.isString(file) || !file) {
          this.create()
          return
        }

        $api.files.create({ file: this.$form.get('image'), }).then((response) => {
          const file = response.data.content.id
          this.create(file)
        }).catch(() => {
          this.$var('load', false)
        })
      }
    },
    create(file = undefined) {
      const data = {
        ...this.$form.get(),
        productId: this.$form.get('product').id,
        supplierId: this.$form.get('supplier')?.id,
        image: file,
      }

      const api = this.offer.id ? $api.app.offers.edit(this.offer.id, data) : $api.app.offers.create(data)
      api.with('product').with('supplier').then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-offer {
  --n-modal-width: 800px;

  .letter {
    padding: 10px 0;
    .title {
      cursor: pointer;
    }
  }
}
</style>
