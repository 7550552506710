<template>
  <div class="tab-info">
    <div class="order-info">
      <block-info-table :order="order" :editable="editable" @reload="$emit('reload')" />
      <block-documents :order="order" :documents="documents" :editable="editable" title="Документы" @reload="$emit('reload')" />
    </div>
    <block-comments :order="order" :editable="editable" />
  </div>
</template>

<script>
import BlockComments from './BlockComments'
import BlockDocuments from './BlockDocuments'
import BlockInfoTable from './BlockInfoTable'

export default {
  name: 'TabInfo',
  components: { BlockInfoTable, BlockDocuments, BlockComments, },
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {}
  },
  computed: {
    documents() {
      return $n.filter(this.order.documents, [ 'type', 'application', ])
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.tab-info {
  padding-top: 20px;
  .order-info {
    display: flex;
  }
}
</style>
