<template>
  <div class="layout-main">
    <n-layout-cool :menu="navigation" :profile="profile">
      <template #logo>Supply System</template>
      <template #logo-min>SS</template>
      <template #avatar><n-image centered round :src="$app.auth.user().avatar || avatar" /></template>
      <template #name>{{ $app.auth.user() ? $app.auth.user().fullName : '' }}</template>
      <template #company-name>{{ company.title }}</template>
      <div slot="content">
        <page-title />
        <router-view />
      </div>
      <div slot="footer">
      </div>
      <div slot="tools">
        <div></div>
      </div>
    </n-layout-cool>

    <cards />
  </div>
</template>

<script>
import avatar from '../../assets/images/avatar.png'
import PageTitle from '../../components/pageTitle/Index'
import Cards from './cards/Index'

export default {
  name: 'MainLayout',
  components: { Cards, PageTitle, },
  data: () => ({
    show: false,
    avatar,
    profile: [
      { title: 'Профиль', icon: 'user', route: 'profile.index', },
      { title: 'Выход', icon: 'sign-out-alt', route: 'login', },
    ],
  }),
  computed: {
    navigation() {
      const data = [
        { title: 'Письма', route: { name: 'inbox.list', }, },
        { title: 'Заявки', route: { name: 'orders.list', }, },
        { title: 'Заказчики', route: { name: 'customers.list', }, },
        { title: 'Поставщики', route: { name: 'providers.list', }, },
        { title: 'Товары', route: { name: 'products.list', }, },
      ]
      if ($app.auth.can('admin')) {
        data.push({ title: 'Настройки', route: { name: 'settings.list', }, })
        data.push({ title: 'Пользователи', route: { name: 'users.list', }, })
      }
      return data
    },
    company() {
      return $app.store.getter('app.company')
    },
  },
  watch: {
    '$store.state.auth.user'() {
      this.checkAuth()
    },
  },
  created() {
    $app.auth.init()
    this.checkAuth()
  },
  methods: {
    checkAuth() {
      if (!$app.auth.user()) {
        this.$router.push({ name: 'login', })
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
.layout-main {
  .n-layout-cool {
    .n-aside {
      .n-logo-full > .n-logo {
        padding: 5px 0 3px;
      }
    }
    --n-layout-padding: 40px;
  }
  .n-layout-sidebar {
    --n-aside-width: 280px;
  }

  .container {
    padding: 10px;
  }
}
</style>
