<template>
  <n-form class="edit-order" @submit="submit">
    <n-loader :loading="$var('load')" />
    <n-items>
      <n-input title="Номер заявки *" v-bind="$form.input('number')" />
      <n-input v-if="withPo" title="Номер PO" v-bind="$form.input('poNumber')" />
      <n-select title="Заказчик *" :data="customers" v-bind="$form.input('customer', 'select')" />
      <n-select title="Баер" :data="contacts" v-bind="$form.input('contact', 'select')" />
      <n-datepicker title="Крайний срок подачи заявки" v-bind="$form.input('endedAt')" />
      <n-textarea title="Описание заявки" v-bind="$form.input('description')" />
      <h3 v-if="withPo">Информация для КП</h3>
      <n-input v-if="withPo" title="Кому" v-bind="$form.input('to')" />
      <n-input v-if="withPo" title="Копия" v-bind="$form.input('copy')" />
      <n-input v-if="withPo" title="Тема" v-bind="$form.input('subject')" />
      <n-select v-if="withPo" title="Валюта КП" :data="currenciesTitles" v-bind="$form.input('currency', 'select')" />
      <n-input v-if="withPo" title="RFQ" v-bind="$form.input('rfq')" />
      <n-input v-if="withPo" title="Incoterms" v-bind="$form.input('incoterms')" />
    </n-items>
    <div class="footer-buttons">
      <n-button @click="$emit('close')">Отменить</n-button>
      <n-button type="submit" color="success">Сохранить</n-button>
    </div>
  </n-form>
</template>

<script>
export default {
  name: 'EditOrder',
  props: {
    order: { type: Object, default: null, },
    letterId: { type: Number, default: 1, },
    withPo: { type: Boolean, default: false, },
  },
  data: () => ({
    data: [],
    customers: [],
    currencies: [],
  }),
  computed: {
    contacts() {
      if (this.$form.get('customer.id')) {
        if (this.$form.get('contact.companyId') !== this.$form.get('customer.id')) {
          this.$form.set('contact', null)
        }
        return $n.reduce(this.customers, (result, item) => {
          if (item.id === this.$form.get('customer.id')) {
            return item.contacts
          }
          return result
        }, [])
      }
      return []
    },
    currenciesTitles() {
      return this.currencies.map((i) => i.title)
    },
  },
  created() {
    this.loadData()
    this.loadCustomers()
    if (this.order) {
      this.$form.init(this.order)
    } else {
      this.$form.init({
        letterId: this.letterId,
        customer: null,
        contact: null,
        number: '',
        poNumber: '',
        description: '',
        to: '',
        copy: '',
        subject: '',
        currency: '',
        rfq: '',
        incoterms: '',
        endedAt: undefined,
      })
    }
    this.$form.rules({
      customer: [ 'required', ],
      number: [ 'required', ],
    })
  },
  methods: {
    loadCustomers() {
      $api.app.customers.get().with('contacts').then((response) => {
        this.customers = response.data.content
      })
    },
    loadData() {
      this.$var('loadData', true)
      $api.app.currencies.get().then((response) => {
        this.currencies = response.data.content
      }).finally(() => {
        this.$var('loadData', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          letterId: this.$form.get('letterId'),
          description: this.$form.get('description'),
          number: this.$form.get('number'),
          poNumber: this.$form.get('poNumber'),
          to: this.$form.get('to'),
          copy: this.$form.get('copy'),
          subject: this.$form.get('subject'),
          currency: this.$form.get('currency'),
          endedAt: this.$form.get('endedAt'),
          rfq: this.$form.get('rfq'),
          incoterms: this.$form.get('incoterms'),
          customerId: this.$form.get('customer')?.id,
          contactId: this.$form.get('contact')?.id,
        }
        const api = this.order ? $api.app.orders.edit(this.order?.id, data) : $api.app.orders.create(data)
        api.then((response) => {
          this.$emit('submit', response.data.content)
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-order {
}
</style>
