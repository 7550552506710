<template>
  <n-modal class="modal-edit" :loading="$var('load')" @close="$emit('close')">
    <h3>Редактирование информации</h3>
    <n-form @submit="submit">
      <n-items>
        <n-input v-bind="$form.input('title')" title="Введите наименование заказчика *" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button type="submit" color="success">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEdit',
  props: {
    customer: { type: Object, required: true, },
  },
  data() {
    return {
      categories: [],
    }
  },
  created() {
    this.$form.init({
      title: this.customer.title,
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      const data = {
        title: this.$form.get('title'),
      }
      $api.app.customers.edit(this.customer.id, data).then(() => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-edit {
  --n-modal-width: 600px;
}
</style>
