<template>
  <div class="customer">
    <c-block-title title="Информация">
      <n-button icon="pen" round @click="() => $var('edit', true)" />
    </c-block-title>

    <c-info-table :data="info" />

    <c-contacts type="customer" :model="customer" />

    <ManagerStats />

    <div class="buyer-stats-container">
      <h3>Статистика по баерам заказчика</h3>
      <table v-if="buyerStats.length !== 0">
        <tr class="table-headers">
          <td>Менеджер</td>
          <td>Заявки в работе</td>
          <td>Количество отправленных КП</td>
          <td>Количество полученных РО</td>
        </tr>
        <tr v-for="item in buyerStats" :key="item.id">
          <td>{{ item }}</td>
          <td>{{ item }}</td>
          <td>{{ item }}</td>
          <td>{{ item }}</td>
        </tr>
      </table>
      <div v-else>
        <h3 class="empty-message">Нет данных по Баерам...</h3>
      </div>
    </div>

    <modal-edit v-if="$var('edit')" :customer="customer" @reload="$emit('reload')" @close="$var('edit', false)" />
  </div>
</template>

<script>
import ManagerStats from './ManagerStats'
import ModalEdit from './ModalEdit'

export default {
  name: 'TabInfo',
  components: { ModalEdit, ManagerStats, },
  props: {
    customer: { type: Object, required: true, },
  },
  data: () => ({
    buyerStats: [],
  }),
  computed: {
    id() {
      return this.$route.query.customer
    },
    info() {
      return [
        { title: 'Наименование', value: this.customer.title, },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
  .customer {
    position: relative;

    .header {
      display: flex;
      align-items: center;
      .edit-button {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
         & button {
           margin-left: 20px;
        }
      }
    }
    .info-container {
      margin-bottom: 30px;
    }
    .manager-stats-container {
      margin-bottom: 50px;
    }
    .buyer-stats-container {
      margin-bottom: 50px
    }
  }
</style>
