<template>
  <n-modal class="modal-edit-order" @close="$emit('close')">
    <h3>Редактирование Заявки</h3>
    <c-edit-order :order="order" with-po @close="$emit('close')" @submit="$emit('submit')" />
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEditOrder',
  props: {
    order: { type: Object, default: () => ({}), },
  },
}
</script>

<style lang="scss" scoped>
.modal-edit-order {
  --n-modal-width: 700px;
}
</style>
