<template>
  <n-modal class="modal-create-kp" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-select title="Формат КП" :data="formats" v-bind="$form.input('type', 'select')" />
        <n-select v-if="$form.get('type')" title="Заказчик" :data="templates" v-bind="$form.input('template', 'select')" />
        <n-button wide type="submit">Сформировать</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalCreateKp',
  props: {
    order: { type: Object, default: () => ({}), },
  },
  data() {
    return {
      formats: [
        { id: 'pdf', title: 'PDF', },
        { id: 'excel', title: 'Excel', },
      ],
    }
  },
  computed: {
    templates() {
      if (this.$form.get('type')) {
        return this.$form.get('type.id') === 'pdf' ? [
          // { id: 'main', title: 'Yars', },
          { id: 'kazminerals', title: 'Kazminerals', },
          { id: 'hcoc', title: 'HCOC', },
          { id: 'tsho', title: 'ТШО', },
        ] : [
          { id: 'kazminerals', title: 'kazminerals', },
          { id: 'hcoc', title: 'HCOC', },
          { id: 'tsho', title: 'ТШО', },
          { id: 'ess', title: 'ESS', },
          { id: 'satu', title: 'satu', },
        ]
      }
      return []
    },
  },
  created() {
    this.$form.init({
      type: null,
      template: null,
    })
  },
  methods: {
    submit() {
      this.createKP(this.$form.get('type.id'), this.$form.get('template.id'))
    },
    createKP(type, template) {
      this.$var('load', true)
      $api.app.orders.kp(this.order.id, type, template).then((response) => {
        this.$emit('reload')
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-create-kp {

}
</style>
