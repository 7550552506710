<template>
  <n-form-item :title="title" class="n-upload-images">
    <div class="image" :class="[{'danger-border': danger}]">
      <input v-if="editable" type="file" accept=".jpg, .jpeg, .png" :multiple="multi" @change="onFileChange" />
      <div class="preview">
        <img v-if="url" :src="url" alt="" />
        <div v-if="!url" class="url">
          <div v-if="editable" class="label"><slot>Добавить фото</slot></div>
        </div>
      </div>
    </div>

    <div v-if="danger" class="danger">{{ danger }}</div>


    <!--        <div class="img-list">-->
    <!--          <div v-for="(file, i) in $form.get('images')" :key="i" class="wrap" @click="remove(i)">-->
    <!--            <div class="item">-->
    <!--              <img :src="getImage(file)" alt="">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
  </n-form-item>
</template>

<script>
export default {
  name: 'NUploadImages',
  props: {
    title: { type: String, default: '', },
    value: { type: [ File, Array, FileList, String, ], default: () => [], },
    multi: { type: Boolean, default: false, },
    danger: { type: [ String, Boolean, ], default: false, },
    input: { type: Function, default: (v) => {}, },
    editable: { type: Boolean, default: true, },

  },
  data() {
    return {
      url: null,
    }
  },
  watch: {
    value(value) {
      if ($n.isString(value)) {
        this.url = value
      }
    },
  },
  created() {
    if ($n.isString(this.value)) {
      this.url = this.value
    }
  },
  methods: {
    onFileChange(e) {
      if (this.multi) {
        const data = $n.concat(this.value, Array.from(e.target.files))
        this.$emit('update:value', data)
        this.input(data)
      } else {
        const file = e.target.files[0]
        this.url = URL.createObjectURL(file)
        this.$emit('update:value', file)
        this.input(file)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.n-upload-images {
  margin-bottom: 10px;
  width: 100%;

  .danger {
    margin-top: 5px;
    color: var(--danger);
  }

  .image {
    background-color: #ECECEC;
    height: 200px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &.danger-border {
      border: 1px solid var(--danger);
      border-radius: 10px;
    }

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      cursor: pointer;
    }
  }


  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      width: 71px;
      height: 62px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      position: relative;
      z-index: 1;
    }
  }

  .url {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      position: relative;
      z-index: 1;
    }
  }
}
</style>
