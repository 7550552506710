<template>
  <n-modal class="card-provider" :loading="$var('load')" @close="$emit('close')">
    <n-tabs state :data="tabs" name="tab" />
    <n-tabs-content name="tab">
      <template #info>
        <tab-info v-if="provider.id" :provider="provider" @reload="load" />
      </template>
      <template #offers>
        <tab-offers :loading="$var('load')" @reload="load" @close="$emit('close')" />
      </template>
      <template #letters>
        <c-letters-history type="supplier" :loading="$var('load')" :owner-id="Number(id)" />
      </template>
    </n-tabs-content>
  </n-modal>
</template>

<script>
import TabInfo from './tab-info/Index'
import TabOffers from './TabOffers'

export default {
  name: 'CardProvider',
  components: { TabOffers, TabInfo, },
  data() {
    return {
      provider: {},
      tabs: [
        { name: 'info', title: 'Информация о поставщике', },
        { name: 'offers', title: 'Ценовые предложения', },
        { name: 'letters', title: 'Переписка с поставщиком', },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.query.provider
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.providers.get(this.id).with('categories').with('contacts').then((response) => {
        this.provider = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-provider {
  --n-modal-width: 1300px;

  &::v-deep>.n-wrapper>.n-card>.content {
    min-height: 400px;
  }
}
</style>
