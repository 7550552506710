<template>
  <n-modal class="provider-list-modal" @close="$emit('close')">
    <h2>Выбор поставщиков</h2>
    <c-providers-list selectable :api="api" />
  </n-modal>
</template>

<script>
export default {
  name: 'Index',
  props: {
    order: { type: Object, default: () => ({}), },
  },
  computed: {
    api() {
      return () => $api.app.providers.get().with('categories').with('contacts').with('orderCount').with('callCount').size(200)
    },
  },
}
</script>

<style lang="scss" scoped>
.provider-list-modal {
  --n-modal-width: 1100px;
}
</style>
