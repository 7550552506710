<template>
  <div class="layout-auth">
    <n-loader :loading="$store.state.app.loading" />
    <div class="sidebar">
      <div class="sidebar-content">
        <div class="logo">Supply System</div>
        <div class="other">
          <!--          <n-select :data="langs" value="Русский" inline />-->
        </div>
        <div class="footer">
          © 2021
        </div>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutAuth',
  data: () => ({
    langs: [ 'Русский', 'Казахский', 'Английский', ],
  }),
}
</script>

<style lang="scss">
.links {
  display: flex;
  flex-direction: row;
  & > * {
    display: inline-block;
    margin: 0 10px;
    opacity: .5;
    transition: opacity linear 100ms;
    &:hover {
      opacity: 1;
    }
  }
  & > *:first-child {
    margin-left: 0;
  }
}

</style>

<style lang="scss" scoped>
@import '../../../node_modules/nast-ui/styles/global';

.layout-auth {
  display: flex;
  min-height: 100vh;

  &::v-deep .n-card {
    margin: 20px 0;
    .content main {
    }
  }

  .sidebar {
    background: #2a2a2a;
    width: 40%;
    height: 100vh;
    position: fixed;

    .sidebar-content {
      margin-top: 15vh;
      margin-bottom: 50px;
      text-align: right;
      padding-right: 50px;
      width: 100%;
      max-width: 350px;
      float: right;
      color: var(--text-color-op);
    }

    .logo {
      font-size: 2em;
      line-height: 1;
      font-weight: 600;
    }

    .other {
      height: 40px;
      --n-input-width: 150px;
    }
    .footer {
      border-top: 1px solid var(--border-color);
      padding: 10px 0 10px 10px;
      font-size: .9em;
      color: #999;
    }
  }
  .content {
    margin: 15vh 40px 0 40%;
    padding-left: 50px;
    padding-bottom: 100px;
    width: 60%;
  }

  @include media(null, md) {
    flex-direction: column;

    .sidebar {
      height: auto;
      position: static;
      width: 100%;
      .sidebar-content {
        margin: 50px 20px;
        text-align: left;
        float: none;
      }
    }
    .content {
      margin: 60px 0 80px 0;
      width: 100%;
      padding: 0 20px;
    }
  }
}
</style>
