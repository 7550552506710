import BaseApi from 'nast-api'
import BaseRequestBuilder from 'nast-api/RequestBuilder'

/**
 *
 */
export default class NastApi extends BaseApi {

}

/**
 *
 */
export class RequestBuilder extends BaseRequestBuilder {

}
