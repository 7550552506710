export default {
  chatClient: [
    {
      body: '1Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut doloremque eaque earum nam neque nihil odio quidem quos veritatis voluptatum?',
      createdAt: '2021-07-21T08:30:35.000000Z',
      email: {
        email: 'test1@renova.st',
        from: '"Test Email 1" <test1@renova.st>',
        id: 1,
        imap: 'imap.yandex.ru',
        imapPort: '993',
        password: '!Q2w3e4r',
        smptPort: null,
        smtp: 'smtp.yandex.ru',
      },
      files: null,
      from: 'mock',
      to: 'Me',
      id: '888',
      title: 'MockTitle',
    },
  ],
  providersList: [
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
    {
      name: 'Microsoft',
      Category: 'Medicine',
      RequestsNumber: '12',
      OrdersNumber: '10',
      Email: 'Microsoft@gmail.com',
    },
    {
      name: 'Google',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '110',
      Email: 'Google@gmail.com',
    },
    {
      name: 'Tesla',
      Category: 'Medicine',
      RequestsNumber: '512',
      OrdersNumber: '170',
      Email: 'Tesla@gmail.com',
    },
    {
      name: 'IBM',
      Category: 'Medicine',
      RequestsNumber: '122',
      OrdersNumber: '210',
      Email: 'IBM@gmail.com',
    },
  ],
  addProvider: [
    {
      name: 'Renova',
      Category: 'Medicine',
      RequestsNumber: '111',
      OrdersNumber: '222',
      Email: 'Renova@renova.st',
    },
  ],
  chatHistory: [
    { id: '1', createdAt: '2021-07-19', from: '"Алина Климова" <alina.k@renova.st>', },
    { id: '2', createdAt: '2021-07-19', from: '"Алина Климова" <alina.k@renova.st>', },
    { id: '3', createdAt: '2021-07-19', from: '"Алина Климова" <alina.k@renova.st>', },
  ],
  providerContacts: [
    {
      phone: '87771234567',
      name: 'Pro Provider Providorovich',
      id: 333,
      email: 'providerEmail@mail.ru',
      companyId: 333,
      companyType: 'Provider',
    },
    {
      phone: '87773214567',
      name: 'Owj Provider Nawem',
      id: 555,
      email: 'sadAsdwreczv@mail.ru',
      companyId: 555,
      companyType: 'Provider',
    },
  ],
  providerOffers: [
    {
      Supplier: 'SpaceX',
      Photo: '',
      StockCode: 'AL123123',
      name: 'FalconX',
      Category: 'Space Ships',
      Status: 'NewOrder',
      Date: '23.03.23',
      OrderNumber: '777',
      Manufacturer: 'SpaceX',
      Characteristics: '100000x2000, 6000kg',
      SellPrice: '$10000000',
      LocalPrice: '$20000000',
    },
  ],
  productHistory: [
    {
      calculations: '',
      supplier: 'TOO "SpaceX"',
      number: '#123987',
      stockCode: 'WIW231',
      name: 'FalconX',
      category: 'Space',
      status: 'New Order',
      date: '20.12.2020',
      costPrice: '$1000000',
      manufacturer: 'TOO "SpaceX"',
      manager: 'Elon Mask',
      sellPrice: '$2000000',
      margin: '$10000',
      address: 'Bekturova 102',
    },
  ],
  manager: [
    {
      contacts: [
        { Manager: 'Elon Mask', Orders: '123', KPs: '100', CompletedOrders: '99', },
        { Manager: 'Tony Stark', Orders: '222', KPs: '200', CompletedOrders: '190', },
      ],
    },
  ],
  managers: [
    { id: 1, name: 'Axwell', email: 'Ciweqn@mail.ru', currentRequests: '13', allRequests: '56', completedRequests: '33', },
    { id: 2, name: 'Oscal', email: 'Ciweqn@mail.ru', currentRequests: '11', allRequests: '32', completedRequests: '22', },
    { id: 3, name: 'Mandy', email: 'Ciweqn@mail.ru', currentRequests: '4', allRequests: '13', completedRequests: '7', },
    { id: 4, name: 'Arthur', email: 'Ciweqn@mail.ru', currentRequests: '15', allRequests: '98', completedRequests: '77', },
    { id: 5, name: 'Lance', email: 'Ciweqn@mail.ru', currentRequests: '9', allRequests: '88', completedRequests: '64', },
  ],
  managerStats: [
    { order: 'Wj23812', status: 'Рассчет КП', date: '01.03.2020', number: '32', finished: true, },
    { order: 'Ba47234', status: 'Новая заявка', date: '11.02.2020', number: '11', finished: true, },
    { order: 'Is347324', status: 'Рассчет КП', date: '25.06.2020', number: '53', finished: false, },
    { order: 'Ok374234', status: 'Не принята', date: '15.01.2020', number: '13', finished: true, },
    { order: 'Vy4327432', status: 'Ожидание PO', date: '23.03.2020', number: '64', finished: false, },
  ],
  buyer: [
    {
      contacts: [
        { Manager: 'SpaceX', Orders: '123', KPs: '100', CompletedOrders: '99', },
        { Manager: 'Iron Man', Orders: '222', KPs: '200', CompletedOrders: '190', },
      ],
    },
  ],
}
