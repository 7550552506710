<template>
  <div class="n-data-component">
    <slot :data="computedData" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import props from './../props'
import { paginated, searched, sorted, } from '../utils'

export default {
  name: 'NDataComponent',
  mixins: [ props, ],
  data() {
    return {
      loadedData: [],
      page: this.firstPage,
      total: 0,
      s_loading: 0,
      stopped: false,
    }
  },
  computed: {
    computedData() {
      const value = this.data ? this.paginated(this.sorted(this.filtered(this.searched(this.data)))) : this.loadedData
      this.updated(value)
      this.$emit('updated', value)
      return value
    },
    update() {
      return debounce(this.s_load, 500)
    },
  },
  watch: {
    api() {
      this.s_load()
    },
    filter() {
      this.update()
    },
    page() {
      this.update()
    },
    size() {
      this.update()
    },
    s_loading() {
      this.$emit('update:loading', !!this.s_loading)
    },
  },
  created() {
    if (this.api) {
      this.s_load()
    } else {
      this.updated(this.computedData)
      this.$emit('updated', this.computedData)
    }
  },
  methods: {
    reload() {
      this.update()
    },
    next() {
      this.page += 1
      this.s_load()
    },
    stop() {
      this.stopped = true
      this.s_loading = 0
    },
    s_load() {
      if (this.api) {
        this.stopped = false
        this.s_loading++

        const params = {
          page: this.page,
          size: this.size,
          search: this.search,
          filter: this.filter,
        }

        // this.beforeLoad(params)
        // this.$emit('beforeLoad', params)

        this.api().params(params).then((response) => {
          if (!this.stopped) {
            this.total = this.getTotalCount(response)
            const data = this.getContent(response)
            this.loadedData = (this.page === this.firstPage) ? data : this.loadedData.concat(data)
            this.$emit('update:data', this.loadedData)

            this.$nextTick(() => {
              // this.loaded()
              // this.$emit('loaded')
              this.$nextTick(() => {
                this.s_loading--
              })
            })
          }
        })
      }
    },
    searched(data) {
      return searched(data, this.search, this.fields)
    },
    filtered(data) {
      return data
    },
    sorted(data) {
      return sorted(data, this.sort, this.fields, this.sortFunction)
    },
    paginated(data) {
      return paginated(data, this.page, this.size)
    },
  },
}
</script>

<style lang="scss" scoped>
  .n-data-component {
  
  }
</style>
