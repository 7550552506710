<template>
  <div class="block-comments">
    <n-loader :loading="$var('load')" />
    <h2>Комментарии к заявке</h2>
    <div v-if="$n.size(comments)" class="list">
      <div v-for="item in comments" :key="item.id" class="comment">
        <div class="avatar"><img :src="item.avatar || avatar" alt="" /></div>
        <div class="content">
          <div class="author-info">
            <div class="name">{{ item.author.fullName }}</div>
            <!--            <div class="position">{{ item.author.position }}</div>-->
            <div class="date">{{ $app.date.format(item.createdAt) }}</div>
          </div>
          <div class="message">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="comments" class="empty">Комментариев нет</div>

    <template v-if="editable">
      <h3>
        Оставить комментарий к заявке
      </h3>
      <n-form class="new-comment" @submit="submit">
        <n-textarea v-bind="$form.input('content')" simple placeholder="Ваш комментарий..." />
        <n-button type="submit">Отправить</n-button>
      </n-form>
    </template>
  </div>
</template>

<script>
import avatar from 'assets/images/avatar.png'

export default {
  name: 'BlockComments',
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      avatar,
      comments: null,
    }
  },
  watch: {
    'order.id'() {
      this.load()
    },
  },
  created() {
    if (this.order.id) {
      this.load()
    }
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.comments.get(this.order.id).with('author').then((response) => {
        this.comments = response.data.content
        this.$form.init({})
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      this.$var('load', true)
      $api.app.comments.create(this.order.id, this.$form.get()).then((result) => {
        this.load()
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block-comments {
  position: relative;

  h2 {
    padding-top: 10px;
    margin: 20px 0 10px;
  }
  .empty {
    font-style: italic;
    opacity: .7;
    font-size: .9em;
    padding: 5px 0 20px;
  }
  .comment {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;

    .avatar {
      min-width: 40px;
      height: 40px;
      img {
        border-radius: 100px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      margin-left: 10px;
      width: 100%;
      .author-info {
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        opacity: .7;
        .name {
          font-weight: 500;
          font-size: .9em;
        }
        .position, .date {
          margin-left: 10px;
          font-size: .8em;
        }
      }
      .message {
        margin-top: 2px;
        font-size: .9em;
      }
    }
  }

  h3 {
    margin: 30px 0 10px;
  }
  .new-comment {
    &::v-deep .n-form-item {
      padding-top: 0;
    }
    .n-button {
      margin-top: 5px;
    }
  }
}
</style>
