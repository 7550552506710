<template>
  <n-modal class="modal-providers-create" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <h3>Создать поставщика</h3>
        <n-input title="Название компании *" v-bind="$form.input('title')" />
        <n-select title="Категории" :data="categories" v-bind="$form.input('categories', 'select')" />
        <h3 style="margin-top: 30px">Представитель</h3>
        <n-input title="Имя" v-bind="$form.input('contact.name')" />
        <n-input title="Телефон" v-bind="$form.input('contact.phone')" />
        <n-input title="Email *" v-bind="$form.input('contact.email')" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button color="success" type="submit">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalProvidersCreate',
  data() {
    return {
      categories: [],
    }
  },
  created() {
    this.loadCategories()
    this.$form.init({
      title: '',
      categories: [],
      contact: {
        name: '',
        phone: '',
        email: '',
      },
    })
    this.$form.rules({
      title: [ 'required', ],
      'contact.phone': [ 'phone', ],
      'contact.email': [ 'email', ],
    })
  },
  methods: {
    loadCategories() {
      this.$var('load', true)
      $api.app.categories.get().then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          ...this.$form.get(),
          categories: $n.map(this.$form.get('categories'), (item) => item.id),
        }
        $api.app.providers.create(data).then((response) => {
          this.$emit('reload')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-providers-create {
}
</style>
