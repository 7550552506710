import UploadImages from './upload-images/Index'
import CLetter from './letter/Index'
import CEditOrder from './edit-order/Index'
import CProvidersList from './providers-list/Index'
import CInfoTable from './info-table/Index'
import CContacts from './contacts/Index'
import CLettersHistory from './letters-history/Index'
import CBlockTitle from './block-title/Index'
import CUserStats from './user-stats/Index'

export default {
  UploadImages,
  CLetter,
  CEditOrder,
  CProvidersList,
  CInfoTable,
  CContacts,
  CLettersHistory,
  CBlockTitle,
  CUserStats,
}
