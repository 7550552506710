<template>
  <div class="send-request">
    <n-card :loading="$var('load')">
      <c-letter-form :files="files" :emails="emails" :products-ids="products" :supplier-contacts="supplierContacts" @submit="submit" />
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'SendRequest',
  data: () => ({
    files: [],
    emails: [],
    model: {},
    oldEmail: '',
    sendInfo: [],
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    type() {
      return this.$route.params.type
    },
    supplierContacts() {
      return this.$route.query['suppliers-contacts']
    },
    products() {
      return this.$route.query['checked-products'].split(',')
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      $api.my.emails().then((result) => {
        this.emails = result.data.content
      })
    },
    submit(value) {
      this.sendInfo = value
      this.$var('load', true)
      if (this.files.length) {
        const apis = $n.map(this.files, (item) => $api.files.create({ file: item, }))
        Promise.all(apis).then((results) => {
          const files = $n.map(results, (item) => item.data.content.id)
          this.save(files).finally(() => {
            this.$var('load', false)
          })
        }).catch(() => {
          this.$var('load', false)
        })
      } else {
        this.save().finally(() => {
          this.$var('load', false)
        })
      }
    },
    save(files) {
      const data = {
        ...this.sendInfo,
        files,
        emailId: '',
      }
      return $api.email.letters.create(data.email.id, data).then((result) => {
        this.$router.push({ name: 'inbox.list', })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.send-request {
  max-width: 1200px;
}
</style>
