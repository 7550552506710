<template>
  <n-form-item class="n-radio" :title="title" @click.native="click">
    <label :class="[ {switch: isSwitch} ]" :for="name">
      <input :id="name" :name="name" :value="val" :checked="checked" type="radio"
             :disabled="text" />
      <span v-if="isSwitch" class="switch-label"></span>
      <span v-if="isSwitch" class="switch-handle"></span>
      <span class="value"><slot /></span>
    </label>
    <div v-if="danger" class="danger-style">
      {{ danger }}
    </div>
  </n-form-item>
</template>

<script>
import isObject from 'lodash/isObject'
import get from 'lodash/get'

export default {
  name: 'NRadio',
  props: {
    title: { type: String, default: '', },
    name: { type: String, default: () => Math.random() + '', },
    value: { type: [ String, Boolean, Object, ], default: false, },
    val: { type: [ String, Boolean, Object, ], default: null, },
    danger: { type: String, default: '', },
    text: { type: Boolean, default: false, },
    isSwitch: { type: Boolean, default: false, },
    itemValue: { type: String, default: 'value', },
    input: { type: Function, default: (value) => {}, },
    'update:value': { type: Function, default: (value) => {}, },
  },
  computed: {
    checked() {
      if (isObject(this.val)) {
        return get(this.val, this.itemValue) === get(this.value, this.itemValue, Math.random())
      }
      return this.val === this.value
    },
  },
  methods: {
    click() {
      if (this.checked) {
        return
      }
      const val = this.val === null ? !this.value : this.val
      this.$emit('input', val)
      this.input(val)
      this.$emit('update:value', val)
      this['update:value'](val)
    },
  },
}
</script>

<style lang="scss" scoped>
.n-radio {
  cursor: pointer;

  &::v-deep .n-content {
    min-height: 18px;
    min-width: 18px;
  }
  label {
    padding: 2px 0;
  }
  
  input[type='radio'] {
    transform: scale(1.5);
    width: 20px;
  }
  .value {
    display: inline-block;
    padding-left: 7px;
    font-size: .9em;
  }
  .switch {
    position: relative;
    width: 100px;
    height: 30px;
    padding-left: 2.5px;
    padding-right: 3px;
    border-radius: 18px;
    cursor: pointer;
    box-sizing:content-box;
  }
  .switch input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing:content-box;
  }
  .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: #eceeef;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    box-sizing:content-box;
  }
  .switch input:checked ~ .switch-label {
    background-color: var(--primary);
  }
  .switch-handle {
    position: absolute;
    top: 0px;
    left: 4px;
    width: 28px;
    height: 28px;
    background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }
  .switch-handle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  }
  .switch input:checked ~ .switch-handle {
    left: 74px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  }
  .switch-label, .switch-handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
  }
  .danger-style{
    color: red;
  }
}
</style>
