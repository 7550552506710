export default {
  app: {
    state: {
      locale: null,
      loading: false,
      
      _save: [ 'locale', ],
    },
    mutations: {
      loading(state, value) {
        state.loading = value
      },
      locale(state, value) {
        state.locale = value
        // $app.api.config({ headers: { 'Locale': value.value, }, })
      },
    },
    namespaced: true,
  },
}
