<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  html() {
    return {
      title: 'Supply System',
      meta: [
        { mid: 'theme-color', name: 'theme-color', content: '#D52B1E', },
      ],
    }
  },
  beforeCreate() {
    $app.auth.init()
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Raleway|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import '~nast-ui/styles/global';

@include initialize((
    default: (
        colors: (
            primary: #1e4cd5,
            secondary: #ffd200,
            warning: #e3b716,
            danger: #D52B1E,
            default: (#f8f8f8, #636363),
        ),
        typography: (
            header-font: 'Roboto, sans-serif',
            text-font: '400 1em Roboto, sans-serif',
            h1-font: '300 2em var(--header-font)',
            h2-font: '500 1.2em var(--header-font)',
            h3-font: '500 1em var(--header-font)',
            text-color: #444,
        ),
    ),
));

html body {
  --border-radius: 7px;
}

.bg-primary { background: var(--primary); color: var(--primary-text); }
.bg-secondary { background: var(--secondary); color: var(--secondary-text); }
.bg-success { background: var(--success); color: var(--success-text); }
.bg-danger { background: var(--danger); color: var(--danger-text); }
.bg-warning { background: var(--warning); color: var(--warning-text); }
.bg-default { background: var(--default); color: var(--default-text); }

.sub, sub {
  font-size: .9em;
  opacity: .9;
}

.table-tools {
  margin-bottom: 10px;
  .n-button:not(:last-child) {
    margin-right: 10px;
  }
}
.footer-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.empty-message {
  text-align: center;
  width: 100%;
  padding: 70px 0;
  font-style: italic;
}
</style>

<style lang="scss" scoped>
div {
  &::v-deep {
    .n-tabs {
      padding-bottom: 10px;
      border-bottom: 2px solid #f0f0f0;
      .n-tab {
        padding: 7px 20px;
        border-width: 2px;
        &.active {
          border-radius: var(--border-radius);
        }
      }
    }

    .n-table {
      .row {
        .cell {
          padding: 10px;
          &:first-child, &:last-child {
            padding: 10px;
          }
        }
        &.header .cell {
          background: #ECEFF1;
          color: #999;
          font-size: .9em;
          font-weight: 300;
          white-space: nowrap;
          padding: 7px 10px;
        }
      }
    }
  }
}
</style>
