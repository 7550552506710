<template>
  <div class="n-search">
    <n-input v-model="s_value" :placeholder="placeholder" @input="debouncedUpdate" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'NSearch',
  props: {
    value: { type: [ String, ], default: '', },
    placeholder: { type: String, default: '', },
  },
  data() {
    return {
      s_value: this.value.replace('search:', ''),
    }
  },
  computed: {
    debouncedUpdate() {
      return debounce(this.s_update, 10)
    },
  },
  watch: {
    value(value) {
      this.s_value = value.replace('search:', '')
    },
  },
  methods: {
    s_update() {
      this.$emit('update:value', 'search:'+this.s_value)
    },
  },
}
</script>

<style lang="scss" scoped>
.n-search {

}
</style>
