<template>
  <div class="c-letter">
    <n-card :loading="loading || $var('load')" style="max-width: 1200px; margin: 0">
      <div v-if="!clear" class="tools">
        <n-divide>
          <n-items>
            <n-link :to="{name: 'inbox.create', query: {type: 'answer', letterId: letter.id,}}"><n-button>Ответить</n-button></n-link>
            <n-link :to="{name: 'inbox.create', query: {type: 'answerAll', letterId: letter.id,}}"><n-button>Ответить всем</n-button></n-link>
            <n-link :to="{name: 'inbox.create', query: {type: 'resend', letterId: letter.id,}}"><n-button>Переслать</n-button></n-link>
            <n-button v-if="!letter.applicationId" color="primary" @click="$var('type', true)">Определить тип письма</n-button>
          </n-items>
          <div>
            <n-button v-if="editable" flat icon="trash" @click="remove" />
          </div>
        </n-divide>
      </div>

      <template v-if="!clear">
        <div v-if="type === 'new'">
          <h3>Новая заявка</h3>
          <c-edit-order :letter-id="letter.id" @submit="create" @close="type=false" />
        </div>
        <div v-if="type === 'provider'">
          <h3>Переписка с поставщиком</h3>
          <set-provider :letter-id="letter.id" @submit="setProvider" @close="type=false" />
        </div>
        <div v-if="type === 'client'">
          <h3>Переписка с заказчиком</h3>
          <set-customer :letter-id="letter.id" @submit="setCustomer" @close="type=false" />
        </div>
        <div v-if="letter.type === 'supplier' && letter.owner" class="price-offers">
          <h3>Ценовые предложения от {{ letter.owner.title }}</h3>
          <price-offers-table :editable="editable" :order-id="letter.applicationId" :provider="letter.owner" :letter="letter" />
        </div>
      </template>
      
      <h3>{{ letter.subject }}</h3>
      <n-items>
        <n-input title="От кого" :value="letter.from" text />
        <n-form-item title="Кому">
          <div v-for="r in letter.recipients" :key="r.id" class="email">
            <span v-if="r.type !== 'to'">{{ r.type }}: </span>
            {{ r.title }}
          </div>
        </n-form-item>
        <n-form-item title="Вложения">
          <template v-if="files.length">
            <div v-for="file in files" :key="file.id">
              <a v-if="!file.hidden" :href="file.src" target="_blank">{{ file.name }}</a>
            </div>
          </template>
          <span v-else>
            -
          </span>
        </n-form-item>
        <n-textarea title="Текст письма" :value="body" text />
      </n-items>
    </n-card>

    <n-modal v-if="$var('type')" @close="$var('type', false)">
      <h3 style="text-align: center">Определить тип письма</h3>
      <n-button wide style="margin-bottom: 20px;" @click="setType('new')">Новая заявка</n-button>
      <n-button wide style="margin-bottom: 20px;" @click="setType('provider')">Переписка с поставщиком</n-button>
      <n-button wide @click="setType('client')">Переписка с заказчиком</n-button>
    </n-modal>
  </div>
</template>

<script>
import PriceOffersTable from 'pages/orders/index/product/PriceOffersTable'
import SetProvider from './SetProvider'
import SetCustomer from './SetCustomer'

export default {
  name: 'CLetter',
  components: { SetCustomer, SetProvider, PriceOffersTable, },
  props: {
    letter: { type: Object, default: () => ({}), },
    loading: { type: Boolean, default: false, },
    editable: { type: Boolean, default: false, },
    clear: { type: Boolean, default: false, },
  },
  computed: {
    type: {
      get() {
        return this.$route.query.type
      },
      set(v) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            type: v || undefined,
          },
        })
      },
    },
    bodyData() {
      if (!this.letter.id) {
        return { body: '', files: [], }
      }

      let body = this.letter.body.replace(/<a /g, '<a target="_blank" ')
      const files = $n.reduce(this.letter.files, (result, file) => {
        result[file.name] = file.src
        return result
      }, {})

      const hiddenFiles = []

      body = body.replace(/src="[^"]+"/g, (str) => {
        let result = str
        $n.each(files, (src, file) => {
          if (str.includes(file)) {
            result = `src="${src}"`
            hiddenFiles.push(file)
          }
        })
        return result
      }).replace('<base', '<base1')

      return {
        body,
        files: hiddenFiles,
      }
    },
    body() {
      return this.bodyData.body
    },
    files() {
      return $n.filter(this.letter.files, (item) => !this.bodyData.files.includes(item.name))
    },
  },
  methods: {
    setType(type) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, type, },
      })
      this.$var('type', false)
    },
    remove() {
      this.$var('load', true)
      $api.email.letters.delete(this.letter.id).then((response) => {
        this.$emit('delete')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    create(value) {
      this.$router.push({ name: 'orders.index', params: { id: value.id, }, query: { page: 'products', }, })
    },
    setProvider(value) {
      this.$router.push({ name: 'orders.index', params: { id: value.applicationId, }, query: { page: 'supplier', }, })
    },
    setCustomer(value) {
      this.$router.push({ name: 'orders.index', params: { id: value.applicationId, }, query: { page: 'customer', }, })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-letter {
  .tools {
    margin-bottom: 20px;
    font-size: .8em;
  }

  .n-textarea {
    &::v-deep .n-content {
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 10px;
    }
  }
  .price-offers {
    width: 100%;
  }
}
</style>
