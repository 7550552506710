<template>
  <div class="manager-stats-container">
    <h3 slot="header">Статистика по менеджерам Yars</h3>
    <table v-if="managerStats.length !== 0">
      <tr class="table-headers">
        <td>Менеджер</td>
        <td>Заявки в работе</td>
        <td>Количество отправленных КП</td>
        <td>Количество полученных РО</td>
      </tr>
      <tr v-for="manager in managerStats" :key="manager.id">
        <td>{{ manager.fullName }}</td>
        <td>{{ manager.statsByCustomer.applicationsCount }}</td>
        <td>{{ manager.statsByCustomer.sentCount }}</td>
        <td>{{ manager.statsByCustomer.finishedCount }}</td>
      </tr>
    </table>
    <div v-else>
      <h3 class="empty-message">Нет данных по Менеджерам...</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManagerStats',
  data() {
    return {
      managerStats: [],
    }
  },
  computed: {
    id() {
      return this.$route.query.customer
    },
  },
  created() {
    this.loadManagerStats()
  },
  methods: {
    loadManagerStats() {
      $api.auth.users.get().filter({ customerId: this.id, }).with('statsByCustomer').all().then((res) => {
        this.managerStats = res.data.content
      })
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  .table-headers {
    width: 20%;
    background: #ECEFF1;
    color: #999;
    font-size: .9em;
    font-weight: 300;
    white-space: nowrap;
  }
}
td {
  padding: 10px 15px;
  border-bottom: 2px solid #f6f6f6;

}
</style>
