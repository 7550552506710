<template>
  <div class="index-page container">
    <n-card :loading="$var('load') || $var('loadEmails')" style="max-width: 1200px;">
      <n-form @submit="submit">
        <n-items>
          <n-form-item title="Контакты поставщиков">
            <div v-for="item in contacts" :key="item.id">{{ item.title }} ({{ item.email }})</div>
          </n-form-item>
          <n-select title="От кого" :data="emails" v-bind="$form.input('email', 'select')"
                    option-title="email" selected-title="email" />
          <n-input title="Копия" v-bind="$form.input('toCC')" :input="() => {}" :update:value="(v) => $form.set('toCC', v)" />
          <n-input title="Скрытая копия" v-bind="$form.input('toBCC')" :input="() => {}" :update:value="(v) => $form.set('toBCC', v)" />
          <n-select title="Язык" :data="langs" :value="lang" @update:value="changeLang" />
          <n-input title="Заголовок" v-bind="$form.input('subject')" />
          <n-textarea title="Текст письма" v-bind="$form.input('body')" />
          <n-select title="Подпись" :value="sign" :data="signs" :update:value="changeSign" item-value="id" />
          <n-textarea v-if="sign.id" title="Текст подписи" v-bind="$form.input('sign')" />
          
          <n-upload title="Приложения" multi :value.sync="files" />
          
          <n-button color="primary" wide type="submit">Отправить</n-button>
        </n-items>
      </n-form>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    lang: { value: 'ru', title: 'Русский', },
    langs: [
      { value: 'ru', title: 'Русский', },
      { value: 'en', title: 'English', },
    ],
    files: [],
    emails: [],
    products: [],
    contacts: [],
    order: {},
    sign: { id: 0, title: 'Без подписи', content: '', },
    signs: [],
  }),
  computed: {
    contactIds() {
      return this.$route.query.contacts
    },
    productIds() {
      return this.$route.query.products
    },
    table1() {
      let result = `
          <table style="border: 1px solid; padding: 5px">
          <tr style="border: 1px solid; padding: 20px">
          <td style="border: 1px solid; padding: 20px">Название</td>
          <td style="border: 1px solid; padding: 20px">Описание</td>
          <td style="border: 1px solid; padding: 20px">Фото</td>
          <td style="border: 1px solid; padding: 20px">Кол-во</td>
          </tr>
`

      for (const i in this.products) {
        const product = this.products[i]
        result += '<tr style="border: 1px solid; padding: 20px">'
        result += `<td style="border: 1px solid; padding: 20px">${product.title}</td>`
        result += `<td style="border: 1px solid; padding: 20px">${product.description}</td>`
        result += `<td style="border: 1px solid; padding: 20px"><div style="width: 50px; height: 50px"><img width="50px" height="50px" style="max-width: 50px; max-height: 50px" src="${product.image}" /></div></td>`
        result += `<td style="border: 1px solid; padding: 20px">${product.quantity} ${product.unit}</td>`
        result += '</tr>'
      }

      result += `</table>`

      return result
    },
    subject() {
      const subjects = {
        ru: 'Запрос ценового предложения.',
        en: 'Request for a quotation.',
      }
      return 'APP#'+this.order.number+'. '+subjects[this.lang.value]
    },
    sample() {
      const table = $n.reduce(this.products, (result, item) => {
        result += `<p><b>${item.title}</b> , ${item.quantity} ${item.unit} <br /> ${item.description}</p>`
        return result
      }, '')

      const contents = {
        ru: `
          <p>
            Здравствуйте, <br>
            Наша компания заинтересована в приобретении Вашей продукции:
          </p>
          ${table}
          <p>
            Предоставьте до ______. Коммерческое предложение на данный товар или аналог и укажите: <br>
            - стоимость <br>
            - наличие или срок поставки, адрес отгрузки по Инкотермс <br>
            - тех. описание <br>
            - производителя <br>
            - изображение (обязательно) <br>
            - габариты и вес товара. <br><br>
            <span style="color: red; font-weight: 700;">Пожалуйста, сохраните в ответе тему сообщения!</span>
          </p>`,
        en: `
          <p>
            Good afternoon!<br />
            Our company is interested in your products:
          </p>
          ${table}
          <p>
            Please send your quotation before ______. Substitutes/Analogues are allowed. Please include in you quotation following information:<br>
            - Price<br />
            - Lead time<br />
            - Data sheet<br />
            - Photo<br />
            - Terms and Conditions of Delivery<br />
            - Available certificates<br />
            - Apprx. Dimensions and weight.<br><br>
            <span style="color: red; font-weight: 700;">Please, do not change subject of the e-mail!</span>
          </p>`,
      }
      
      return contents[this.lang.value]
    },
  },
  created() {
    this.load()
    this.loadSigns()
  },
  methods: {
    changeLang(value) {
      this.lang = value
      this.$form.set('body', this.sample)
      this.$form.set('subject', this.subject)
    },
    load() {
      this.$var('load', true)
      const apis = [
        $api.my.emails(),
        $api.app.products.get().filter({ id: 'in:'+this.productIds, }).with('application'),
        $api.app.contacts.get().filter({ id: 'in:'+this.contactIds, }),
      ]
      Promise.all(apis).then((result) => {
        this.emails = result[0].data.content
        this.products = result[1].data.content
        this.contacts = result[2].data.content
        this.order = $n.get(this.products, '0.application')
        this.$form.init({
          email: this.emails[0],
          subject: this.subject,
          body: this.sample,
          contacts: this.contactIds.split(','),
          toCC: [],
          toBCC: [],
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadSigns() {
      $api.auth.signs.get($app.auth.user().id).then((result) => {
        this.signs = [
          { id: 0, title: 'Без подписи', content: '', },
          ...result.data.content,
        ]
      })
    },
    changeSign(value) {
      this.$form.set('sign', value.content)
      this.sign = value
    },
    submit() {
      this.$var('load', true)
      if (this.files.length) {
        const apis = $n.map(this.files, (item) => $api.files.create({ file: item, }))
        Promise.all(apis).then((results) => {
          const files = $n.map(results, (item) => item.data.content.id)
          this.save(files)
        }).catch(() => {
          this.$var('load', false)
        })
      } else {
        this.save()
      }
    },
    save(files) {
      const recipients = []
      $n.each(this.$form.get('toCC'), (i) => recipients.push({ email: i, type: 'cc', }))
      $n.each(this.$form.get('toBCC'), (i) => recipients.push({ email: i, type: 'bcc', }))
      const data = {
        ...this.$form.get(),
        body: this.$form.get('body') + this.$form.get('sign'),
        recipients,
        files,
      }
      $api.email.letters.createRequest(this.$form.get('email').id, data).then((response) => {
        this.$router.push({ name: 'orders.index', params: { id: this.order.id, }, query: { page: 'supplier', }, })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .index-page {

    &::v-deep {
      .editr--content {
        height: 500px;
      }
    }
  }
</style>
