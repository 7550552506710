<template>
  <div class="price-offers-table">
    <n-divide class="table-tools">
      <div>
        <n-button v-if="editable" @click="offerId = -1">Добавить ценовое предложение</n-button>
      </div>
    </n-divide>
    <n-table :data="offers" :columns="columns">
      <template #radio="{item}">
        <n-radio v-if="editable" :value="calculation.priceOffer" :val="item" item-value="id" @update:value="changePrice" />
      </template>
      <template #price="{item}">
        {{ item.price }} {{ item.currency }}
      </template>
      <template #description="{item}">
        <div v-html="$app.secure.clean(item.description)" />
      </template>
      <template #image="{item}">
        <n-image class="product-image" :src="item.image" />
      </template>
      <template #tools="{item}">
        <n-button round icon="eye" @click="offerId = item.id" />
      </template>
    </n-table>

    <modal-offer v-if="offerId" :order-id="orderId" :product="product" :provider="provider" :editable="editable" :letter="letter"
                 @close="offerId = undefined" @reload="reload" />
  </div>
</template>

<script>
import ModalOffer from './ModalOffer'

export default {
  name: 'PriceOffersTable',
  components: { ModalOffer, },
  props: {
    editable: { type: Boolean, default: false, },
    orderId: { type: Number, default: 0, },
    product: { type: Object, default: () => null, },
    provider: { type: Object, default: () => null, },
    letter: { type: Object, default: () => null, },
    calculation: { type: Object, default: null, },
    withCalc: { type: Boolean, default: false, },
  },
  data() {
    return {
      offers: [],
      s_offerId: undefined,
    }
  },
  computed: {
    offerId: {
      get() {
        return this.s_offerId
      },
      set(value) {
        this.s_offerId = value === undefined ? undefined : value * 1
        if (value !== this.$route.query.offer) {
          this.$router.push({ query: { ...this.$route.query, offer: value, }, })
        }
      },
    },
    columns() {
      let columns = []
      if (this.withCalc) {
        columns.push({ name: 'radio', title: '', })
      }
      columns = $n.concat([], columns, [
        { name: 'supplier.title', title: 'Поставщик', },
        { name: 'price', title: 'Цена', },
        { name: 'image', title: 'Фото', },
        { name: 'title', title: 'Название', },
        { name: 'weight', title: 'Габариты и вес товара', },
        { name: 'availability', title: 'Наличие / Срок доставки', },
        { name: 'shippingAddress', title: 'Адрес отгрузки', },
        { name: 'tools', title: '', },
      ])
      return columns
    },
  },
  watch: {
    product() {
      this.load()
    },
    provider() {
      this.load()
    },
  },
  created() {
    this.load()
    this.offerId = this.$route.query.offer
  },
  methods: {
    reload() {
      this.$emit('reload')
      this.load()
    },
    load() {
      this.offers = []
      let api = null
      if (this.product?.id) {
        api = $api.app.offers.get().filter({ productId: this.product.id, })
      } else if (this.provider?.id) {
        api = $api.app.offers.get().filter({ supplierId: this.provider.id, })
      }
      if (api) {
        this.$var('load', true)
        api.filter({ applicationId: this.orderId, }).with('supplier').then((response) => {
          this.offers = response.data.content
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    changePrice(item) {
      this.$emit('update:calculation', { priceOfferId: item.id, })
    },
  },
}
</script>

<style lang="scss" scoped>
.price-offers-table {
  .n-table {
    font-size: .8em;
  }
  .product-image {
    width: 50px;
    height: 50px;
    &::v-deep img { object-fit: cover; }
  }
}
</style>
