<template>
  <div class="page-login">
    <div class="title">
      <h1>Вход в систему</h1>
    </div>
    <n-card class="auth-form" :loading="$var('loading')">
      <n-form class="content" @submit="submit">
        <n-input title="Логин" v-bind="$form.input('login')" />
        <n-input title="Пароль" type="password" v-bind="$form.input('password')" />
        <n-button color="primary" type="submit" wide>Войти</n-button>
      </n-form>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'PageLogin',
  data: () => ({
  }),
  created() {
    this.$form.init({
      login: '',
      password: '',
    })
    this.$form.rules({
      login: [ 'required', ],
      password: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('loading', true)
        $api.auth.login(this.$form.get()).then((response) => {
          const user = response.data.content.user
          user.grants = response.data.content.grants
          $app.auth.login(user, response.data.content.token, response.data.content.grants)
          this.$router.push({ name: 'inbox.list', })
          this.$var('loading', false)
        }).catch(() => {
          this.$set(this.form_errors, 'default', {
            'login': [ 'Неверный ИИН или пароль', ],
          })
          this.$var('loading', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-login {
  max-width: 500px;

  .title {
    h1 {
      margin: 0;
    }
  }

  .auth-form {
    .content {
      &>* {
        margin: 20px 0;
      }
      .n-button {
        --n-button-padding: 12px 20px;
        margin: 30px 0 10px;
      }
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    & > * {
      display: inline-block;
      margin: 0 10px;
      opacity: .5;
      transition: opacity linear 100ms;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
