<template>
  <div class="page-profile-index">
    <n-card>
      <h2>Подписи</h2>

      <div class="table-tools">
        <n-divide>
          <div></div>
          <div><n-button @click="$var('sign', true)">Создать</n-button></div>
        </n-divide>
      </div>
      <n-table :data="signs" :columns="columns" :loading="$var('load')">
        <template #content="{item}">
          <div v-html="item.content" />
        </template>
        <template #tools="{item}">
          <n-button icon="pen" @click="$var('sign', item)" />
        </template>
      </n-table>
    </n-card>

    <modal-sign v-if="$var('sign')" :sign="$var('sign')" @reload="load" @close="$var('sign', false)" />
  </div>
</template>

<script>
import ModalSign from './ModalSign'

export default {
  name: 'PageProfileIndex',
  components: { ModalSign, },
  data() {
    return {
      signs: [],
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'content', title: 'Подпись', },
        { name: 'tools', title: '', },
      ],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.auth.signs.get($app.auth.user().id).then((response) => {
        this.signs = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-profile-index {

}
</style>
