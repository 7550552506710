<template>
  <div class="cards">
    <card-provider v-if="type === 'provider'" @close="close" />
    <card-customer v-if="type === 'customer'" @close="close" />
    <card-product v-if="type === 'product'" @close="close" />
  </div>
</template>

<script>
import CardProvider from './provider/Index'
import CardCustomer from './customer/Index'
import CardProduct from './product/Index'

export default {
  name: 'Cards',
  components: { CardProduct, CardProvider, CardCustomer, },
  computed: {
    type() {
      const types = [ 'provider', 'customer', 'product', ]
      // eslint-disable-next-line guard-for-in
      for (const i in types) {
        const value = $n.get(this.$route.query, types[i])
        if (value) {
          return types[i]
        }
      }
      return ''
    },
  },
  methods: {
    close() {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, [this.type]: undefined, }, })
    },
  },
}
</script>

<style lang="scss" scoped>
.cards {

}
</style>
