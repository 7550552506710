<template>
  <div class="c-providers-list">
    <div v-if="$n.size(checkedProviders)" class="selected-providers">
      <h3>Выбранные контакты</h3>
      <div v-for="item in checkedProviders" :key="item.id">
        {{ item.title }}
      </div>
      <n-link :to="{ name: 'inbox.request', query: { products: productsIds, contacts: providersIds, },}">
        <n-button color="primary">Сформировать запрос</n-button>
      </n-link>
    </div>

    <n-data-component ref="data" :api="api" :data.sync="providers" :loading.sync="loading" v-bind="params" :size="200" />

    <div class="filters">
      <n-search :value="params.filter.title" placeholder="Поиск по названию" @update:value="search" />
      <n-search :value="params.filter.applicationNumber" placeholder="Поиск по заявке (номеру, PO)" @update:value="searchNumber" />
      <n-search :value="params.filter.byProduct" placeholder="Поиск по товару (название, артикул, производитель, название из ЦП)" @update:value="searchByProduct" />
      <n-select placeholder="Категория" :data="categories" :value.sync="category" item-value="id" @update:value="selectCategory" />
    </div>

    <n-table :loading="loading" :data="providers" :columns="columns">
      <template #createdAt="{item}">
        {{ $app.date.format(item.createdAt) }}
      </template>
      <template #categories="{item}">
        <div class="categories">
          {{ $n.map(item.categories, (i) => i.title).join(', ') }}
        </div>
      </template>
      <template #contacts="{item}">
        <template v-if="item.contacts.length">
          <div v-for="contact in item.contacts" :key="contact.id" class="contact">
            <input v-if="selectable" v-model="checkedProviders" type="checkbox" :value="contact" />
            {{ contact.title }} (<span>{{ contact.email }}</span>)
          </div>  
        </template>
        <template v-else>
          <span>-</span>
        </template>
      </template>
      <template #tools="{item}">
        <n-link :to="{ query: { ...$route.query, provider: item.id } }">
          <n-button icon="eye" round />
        </n-link>
      </template>
      <template #remove="{item}">
        <n-button round icon="trash" @click="remove(item.id)" />
      </template>
    </n-table>
  </div>
</template>

<script>
export default {
  name: 'CProvidersList',
  props: {
    api: { type: Function, required: true, },
    selectable: { type: Boolean, default: false, },
  },
  data() {
    return {
      providers: [],
      loading: true,
      params: {
        filter: {},
      },
      selected: [],
      checkedProviders: [],
      category: { title: 'Все категории', id: undefined, },
      categories: [],
      products: [],
      columns: [
        { name: 'title', title: 'Название', },
        { name: 'contacts', title: 'Контакты', },
        { name: 'categories', title: 'Категории', },
        { name: 'callCount', title: 'Кол-во обращений', },
        { name: 'orderCount', title: 'Кол-во заказов', },
        { name: 'tools', title: '', },
        // { name: 'remove', title: '', },
      ],
    }
  },
  computed: {
    productsIds() {
      return this.$route.query.products
    },
    providersIds() {
      return $n.map(this.checkedProviders, (i) => i.id).join(',')
    },
  },
  created() {
    this.loadCategories()
  },
  methods: {
    reload() {
      this.$refs.data.reload()
    },
    loadCategories() {
      $api.app.categories.get().then((response) => {
        this.categories = [
          { title: 'Все категории', id: undefined, },
          ...response.data.content,
        ]
      })
    },
    selectCategory(value) {
      this.category = value
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          categoryId: value.id,
        },
      }
    },
    search(value) {
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          title: value,
        },
      }
    },
    searchNumber(value) {
      if (value && !value.replace('search:', '')) {
        value = undefined
      }
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          applicationNumber: value,
        },
      }
    },
    searchByProduct(value) {
      if (value && !value.replace('search:', '')) {
        value = undefined
      }
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          byProduct: value,
        },
      }
    },
    remove(id) {
      this.$var('load', true)
      $api.app.providers.delete(id).then(() => {
        this.load()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-providers-list {
  .selected-providers {
    margin-bottom: 20px;
  }
  .filters {
    max-width: 600px;
    margin: 20px 0;
    &>* {
      margin: 10px 0;
    }
  }
}
</style>
