var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"price-offers-table"},[_c('n-divide',{staticClass:"table-tools"},[_c('div',[(_vm.editable)?_c('n-button',{on:{"click":function($event){_vm.offerId = -1}}},[_vm._v("Добавить ценовое предложение")]):_vm._e()],1)]),_c('n-table',{attrs:{"data":_vm.offers,"columns":_vm.columns},scopedSlots:_vm._u([{key:"radio",fn:function(ref){
var item = ref.item;
return [(_vm.editable)?_c('n-radio',{attrs:{"value":_vm.calculation.priceOffer,"val":item,"item-value":"id"},on:{"update:value":_vm.changePrice}}):_vm._e()]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.currency)+" ")]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$app.secure.clean(item.description))}})]}},{key:"image",fn:function(ref){
var item = ref.item;
return [_c('n-image',{staticClass:"product-image",attrs:{"src":item.image}})]}},{key:"tools",fn:function(ref){
var item = ref.item;
return [_c('n-button',{attrs:{"round":"","icon":"eye"},on:{"click":function($event){_vm.offerId = item.id}}})]}}])}),(_vm.offerId)?_c('modal-offer',{attrs:{"order-id":_vm.orderId,"product":_vm.product,"provider":_vm.provider,"editable":_vm.editable,"letter":_vm.letter},on:{"close":function($event){_vm.offerId = undefined},"reload":_vm.reload}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }