<template>
  <div>
    <div class="index-page container">
      <c-letter :letter="letter" :loading="$var('load')" @delete="$router.push(-1)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    letter: {},
    data: [],
    columns: [],
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.email.letters.get(this.id).with('owner').with('files').with('recipients').then((response) => {
        this.letter = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.index-page {
  margin: 20px;
}
</style>
