var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-providers-list"},[(_vm.$n.size(_vm.checkedProviders))?_c('div',{staticClass:"selected-providers"},[_c('h3',[_vm._v("Выбранные контакты")]),_vm._l((_vm.checkedProviders),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item.title)+" ")])}),_c('n-link',{attrs:{"to":{ name: 'inbox.request', query: { products: _vm.productsIds, contacts: _vm.providersIds, },}}},[_c('n-button',{attrs:{"color":"primary"}},[_vm._v("Сформировать запрос")])],1)],2):_vm._e(),_c('n-data-component',_vm._b({ref:"data",attrs:{"api":_vm.api,"data":_vm.providers,"loading":_vm.loading,"size":200},on:{"update:data":function($event){_vm.providers=$event},"update:loading":function($event){_vm.loading=$event}}},'n-data-component',_vm.params,false)),_c('div',{staticClass:"filters"},[_c('n-search',{attrs:{"value":_vm.params.filter.title,"placeholder":"Поиск по названию"},on:{"update:value":_vm.search}}),_c('n-search',{attrs:{"value":_vm.params.filter.applicationNumber,"placeholder":"Поиск по заявке (номеру, PO)"},on:{"update:value":_vm.searchNumber}}),_c('n-search',{attrs:{"value":_vm.params.filter.byProduct,"placeholder":"Поиск по товару (название, артикул, производитель, название из ЦП)"},on:{"update:value":_vm.searchByProduct}}),_c('n-select',{attrs:{"placeholder":"Категория","data":_vm.categories,"value":_vm.category,"item-value":"id"},on:{"update:value":[function($event){_vm.category=$event},_vm.selectCategory]}})],1),_c('n-table',{attrs:{"loading":_vm.loading,"data":_vm.providers,"columns":_vm.columns},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$app.date.format(item.createdAt))+" ")]}},{key:"categories",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"categories"},[_vm._v(" "+_vm._s(_vm.$n.map(item.categories, function (i) { return i.title; }).join(', '))+" ")])]}},{key:"contacts",fn:function(ref){
var item = ref.item;
return [(item.contacts.length)?_vm._l((item.contacts),function(contact){return _c('div',{key:contact.id,staticClass:"contact"},[(_vm.selectable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedProviders),expression:"checkedProviders"}],attrs:{"type":"checkbox"},domProps:{"value":contact,"checked":Array.isArray(_vm.checkedProviders)?_vm._i(_vm.checkedProviders,contact)>-1:(_vm.checkedProviders)},on:{"change":function($event){var $$a=_vm.checkedProviders,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=contact,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedProviders=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedProviders=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedProviders=$$c}}}}):_vm._e(),_vm._v(" "+_vm._s(contact.title)+" ("),_c('span',[_vm._v(_vm._s(contact.email))]),_vm._v(") ")])}):[_c('span',[_vm._v("-")])]]}},{key:"tools",fn:function(ref){
var item = ref.item;
return [_c('n-link',{attrs:{"to":{ query: Object.assign({}, _vm.$route.query, {provider: item.id}) }}},[_c('n-button',{attrs:{"icon":"eye","round":""}})],1)]}},{key:"remove",fn:function(ref){
var item = ref.item;
return [_c('n-button',{attrs:{"round":"","icon":"trash"},on:{"click":function($event){return _vm.remove(item.id)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }