<template>
  <div class="tab-history">
    <div class="applications-table">
      <n-loader :loading="$var('load')" />
      <table v-if="applications.length !== 0 || $var('load')">
        <tr class="table-headers">
          <td>№ Заявки</td>
          <td>Статус</td>
          <td>Менеджер</td>
          <td>Описание</td>
          <td>Заказчик</td>
        </tr>
        <tr v-for="application in applications" :key="application.id">
          <td>
            <n-link :to="{name: 'orders.index', params: {id: application.id}, query: {page: 'products', 'order-product': id, tab: 'calc' }}">
              <span v-if="application.number">
                {{ application.number }}
              </span>
              <span v-else>
                {{ application.id }}
              </span>
            </n-link>
          </td>
          <td>
            <span :class="['status', 'bg-'+$n.status(application.status).color, ]">
              {{ $n.status(application.status).title }}
            </span>
          </td>
          <td>
            <span v-if="application.manager">
              {{ application.manager.fullName }}
            </span>
            <span v-else>
              -
            </span>
          </td>
          <td v-html="$app.secure.clean(application.description)">
          </td>
          <td>
            {{ application.customer.name }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabHistory',
  props: {
    product: { type: Object, required: true, },
  },
  data() {
    return {
      applications: [],
    }
  },
  computed: {
    id() {
      return this.$route.query.product
    },
  },
  created() {
    this.loadApplications()
  },
  methods: {
    loadApplications() {
      this.$var('load', true)
      $api.app.products.productHistory(this.id).with('customer').with('manager').then((res) => {
        this.applications = res.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.tab-history {
  margin-top: 20px;
  .applications-table {
    position: relative;
    table {
      width: 100%;
      .table-headers {
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
    }
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;
    }
    .search-container {
      margin-bottom: 70px;
    }
  }
}
</style>
