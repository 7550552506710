import mocks from './mocks'
/**
 *
 */
export default class RestApi {
  /**
     * Auth
     */
  auth = {
    info: () => $app.api.get([ 'auth/info', ]),
    login: (data) => $app.api.post('auth/login').data(data),
    register: (data, token = '') => $app.api.post([ 'auth/register*', token, ]).data(data),
    users: {
      get: (id) => $app.api.get([ 'users*', id, ]),
      emails: (id) => $app.api.get([ 'users*/emails', id, ]),
      create: (data) => $app.api.post([ 'users', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'users*', id, ]),
    },
    signs: {
      get: (userId) => $app.api.get([ 'users*/signs', userId, ]),
      create: (userId, data) => $app.api.post([ 'users*/signs', userId, ]).data(data),
      edit: (id, data) => $app.api.patch([ 'users/signs*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'users/signs*', id, ]),
    },
    roles: {
      get: (id) => $app.api.get([ 'auth/roles*', id, ]),
    },
  }

  /**
     * Base
     */
  files = {
    get: (id) => $app.api.get([ 'files*', id, ]),
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
    delete: (id) => $app.api.delete([ 'files*', id, ]),
  }
  locale = {
    get: (id) => $app.api.get([ 'locales*', id, ]),
    set: (id) => $app.api.post([ 'locales*', id, ]),
    elements: {
      get: () => $app.api.get([ 'elements', ]),
    },
  }
  translations = {
    getByTag: (tag) => $app.api.get([ 'base/translations*', tag, ]),
    edit: (id, data) => $app.api.patch([ 'base/translations*', id, ]).data(data),
  }

  my = {
    letters: () => $app.api.get([ 'my/letters', ]),
    emails: () => $app.api.get([ 'my/emails', ]),
  }

  /**
     * Email
     */
  email = {
    emails: {
      get: (id) => $app.api.get([ 'emails*', id, ]),
      create: (data) => $app.api.post([ 'emails', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'emails*', id, ]).data(data),
      sync: (id) => $app.api.post([ 'emails*/sync', id, ]),
      delete: (id) => $app.api.delete([ 'emails*', id, ]),
      attach: (userId, emailId) => $app.api.post([ 'emails*/users*', emailId, userId, ]),
      detach: (userId, emailId) => $app.api.delete([ 'emails*/users*', emailId, userId, ]),
    },
    letters: {
      get: (id) => $app.api.get([ 'emails/letters*', id, ]),
      getByFolder: (folderId) => $app.api.get([ 'emails/folders*/letters', folderId, ]),
      create: (emailId, data) => $app.api.post([ 'emails*/letters', emailId, ]).data(data),
      createRequest: (emailId, data) => $app.api.post([ 'emails*/letters/request', emailId, ]).data(data),
      move: (id, folderId) => $app.api.post([ 'emails/letters*/move*', id, folderId, ]),
      type: (id, type, data) => $app.api.patch([ 'emails/letters*/type*', id, type, ]).data(data),
      delete: (id) => $app.api.delete([ 'emails/letters*', id, ]),
    },
  }

  /**
     * App
     */
  app = {
    offers: {
      get: (id) => $app.api.get([ 'priceOffers*', id, ]),
      create: (data) => $app.api.post([ 'priceOffers', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'priceOffers*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'priceOffers*', id, ]),
    },
    currencies: {
      create: (data) => $app.api.post([ 'currencies', ]).data(data),
      get: (id) => $app.api.get([ 'currencies*', id, ]),
      edit: (id, data) => $app.api.patch([ 'currencies*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'currencies*', id, ]),
    },
    memo: {
      edit: (id, data) => $app.api.patch([ 'data*', id, ]).data(data),
    },
    appCalc: {
      edit: (id, data) => $app.api.patch([ 'data*', id, ]).data(data),
    },
    manager: {
      contacts: (id) => $app.api.get([ 'contacts', id, ]).mock(() => {
        return mocks.manager
      }),
    },
    companyInfo: {
      get: (id) => $app.api.get([ 'companyInfos*', id, ]),
    },
    document: {
      get: (id) => $app.api.get([ 'documents*', id, ]),
      create: (data) => $app.api.post([ 'documents', ]).data(data),
      delete: (id) => $app.api.delete([ 'documents*', id, ]),
    },
    commercialOffer: {
      get: (id) => $app.api.get([ 'commercialOffers*', id, ]),
      create: (data) => $app.api.post([ 'commercialOffers', ]).data(data),
    },
    buyer: {
      contacts: (id) => $app.api.get([ 'contacts', id, ]).mock(() => {
        return mocks.buyer
      }),
    },
    chatHistory: {
      get: (id) => $app.api.get([ 'chatHistory', id, ]).mock(() => {
        return mocks.chatHistory
      }),
    },
    letters: {
      get: (id) => $app.api.get([ 'letters*', id, ]),
      create: (data) => $app.api.post([ 'letters', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'letters*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'letters*', id, ]),
      editStatus: (id, data) => $app.api.patch([ 'letters*/changeStatus', id, ]).data(data),
    },
    priceOffers: {
      get: (id) => $app.api.get([ 'priceOffers*', id, ]),
      create: (data) => $app.api.post([ 'priceOffers', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'priceOffers*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'priceOffers*', id, ]),
    },
    emails: {
      get: (id) => $app.api.get([ 'emails*', id, ]),
      create: (data) => $app.api.post([ 'emails', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'emails*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'emails*', id, ]),
    },
    customers: {
      get: (id) => $app.api.get([ 'customers*', id, ]),
      create: (data) => $app.api.post([ 'customers', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'customers*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'customers*', id, ]),
      getLetters: (id) => $app.api.get([ 'customers*/letters', id, ]),
      getContacts: (id) => $app.api.get([ 'customers*/contacts', id, ]),
      getOrders: (id) => $app.api.get([ 'customers*/applications', id, ]),
    },
    managers: {
      getStats: (id) => $app.api.get([ 'managersStats*', id, ]),
      create: (data) => $app.api.post([ 'managers', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'managers*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'managers*', id, ]),
    },
    providers: {
      get: (id) => $app.api.get([ 'suppliers*', id, ]),
      create: (data) => $app.api.post([ 'suppliers', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'suppliers*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'suppliers*', id, ]),
      getLetters: (id) => $app.api.get([ 'suppliers*/letters', id, ]),
      getContacts: (id) => $app.api.get([ 'suppliers*/contacts', id, ]),
    },
    orders: {
      get: (id) => $app.api.get([ 'applications*', id, ]),
      letters: (id) => $app.api.get([ 'applications*/letters', id, ]),
      create: (data) => $app.api.post([ 'applications', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'applications*', id, ]).data(data),
      kp: (id, type, template) => {
        if (type === 'pdf') {
          return $app.api.post([ 'applications*/pdf', id, ]).data({ patternNumber: template, })
        } else {
          return $app.api.post([ 'applications*/excel', id, ]).data({ patternName: template, })
        }
      },
      delete: (id) => $app.api.delete([ 'applications*', id, ]),
      connectManager: ({ applicationId, }, data) => $app.api.patch([ 'applications*/connectManager', parseInt(applicationId), ]).data(data),
      fields: {
        get: (id) => $app.api.get([ 'applications*/fields', id, ]),
        create: (id, data) => $app.api.post([ 'applications*/fields', id, ]).data(data),
        edit: (id, fieldId, data) => $app.api.patch([ 'applications*/fields*', id, fieldId, ]).data(data),
        delete: (id, fieldId) => $app.api.delete([ 'applications*/fields*', id, fieldId, ]),
      },
    },
    products: {
      get: (id) => $app.api.get([ 'products*', id, ]),
      create: (data) => $app.api.post([ 'products', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'products*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'products*', id, ]),
      calc: (id, data) => $app.api.patch([ 'products*/calc', id, ]).data(data),
      loadFromExcel: (data) => $app.api.post([ 'products/loadFromExcel', ] ).data(data),
      orders: (id) => $app.api.get([ 'products*/applications', id, ]),
    },
    categories: {
      get: (id) => $app.api.get([ 'categories*', id, ]),
      create: (data) => $app.api.post([ 'categories', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'categories*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'categories*', id, ]),
    },
    comments: {
      get: (orderId) => $app.api.get([ 'applications*/comments', orderId, ]),
      create: (orderId, data) => $app.api.post([ 'applications*/comments', orderId, ]).data(data),
      delete: (orderId, id) => $app.api.delete([ 'applications*/comments*', orderId, id, ]),
    },
    contacts: {
      get: () => $app.api.get([ 'contacts', ]),
      create: (data) => $app.api.post([ 'contacts', ]).data(data),
      edit: (id, data) => $app.api.patch([ 'contacts*', id, ]).data(data),
      delete: (id) => $app.api.delete([ 'contacts*', id, ]),
    },
  }
}
