var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-products"},[_c('n-divide',{staticClass:"table-tools"},[_c('div',[(_vm.editable && _vm.order.status === 'calculation')?[(!_vm.checkedProducts)?_c('n-button',{on:{"click":function($event){_vm.checkedProducts = []}}},[_vm._v("Сформировать запрос поставщику")]):_vm._e(),(_vm.checkedProducts && !_vm.$n.size(_vm.checkedProducts))?_c('n-button',{attrs:{"color":"primary","disabled":""}},[_vm._v("Выберете товары для отправки поставщику")]):_vm._e(),(_vm.$n.size(_vm.checkedProducts))?_c('n-button',{attrs:{"color":"primary"},on:{"click":function($event){_vm.chooseProvider = true}}},[_vm._v("К выбору поставщиков")]):_vm._e(),(_vm.checkedProducts)?_c('n-button',{on:{"click":function($event){_vm.checkedProducts = undefined}}},[_vm._v("Отменить")]):_vm._e()]:_vm._e()],2),(_vm.editable)?_c('div',[_c('n-button',{on:{"click":function($event){_vm.productId = -1}}},[_vm._v("Добавить товар")]),_c('n-button',{on:{"click":function($event){return _vm.$var('fromExcel', true)}}},[_vm._v("Загрузить товары из Excel")])],1):_vm._e()]),_c('n-table',{attrs:{"data":_vm.products,"columns":_vm.columns,"loading":_vm.$var('load')},scopedSlots:_vm._u([{key:"checkbox",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedProducts),expression:"checkedProducts"}],attrs:{"type":"checkbox"},domProps:{"value":item.id,"checked":Array.isArray(_vm.checkedProducts)?_vm._i(_vm.checkedProducts,item.id)>-1:(_vm.checkedProducts)},on:{"change":function($event){var $$a=_vm.checkedProducts,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedProducts=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedProducts=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedProducts=$$c}}}})]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$app.secure.clean(item.description))}})]}},{key:"image",fn:function(ref){
var item = ref.item;
return [_c('n-image',{staticClass:"product-image",attrs:{"src":item.image}})]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.total && item.quantity) ? _vm.$n.numberFormat(item.total / item.quantity) : '-')+" "+_vm._s((item.calculation) ? item.calculation.currency2 : '')+" ")]}},{key:"tools",fn:function(ref){
var item = ref.item;
return [_c('n-button',{attrs:{"round":"","icon":"eye"},on:{"click":function($event){_vm.productId = item.id}}})]}}])}),(_vm.$var('fromExcel'))?_c('load-excel',{attrs:{"order-id":_vm.order.id},on:{"close":function($event){return _vm.$var('fromExcel', false)},"reload":_vm.load}}):_vm._e(),(_vm.productId)?_c('modal-product',{attrs:{"order":_vm.order,"editable":_vm.editable},on:{"close":function($event){_vm.productId = undefined},"reload":function($event){return _vm.$emit('reload')}}}):_vm._e(),(_vm.chooseProvider)?_c('modal-providers',{attrs:{"order":_vm.order},on:{"close":function($event){_vm.chooseProvider = undefined},"reload":function($event){return _vm.$emit('reload')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }