<template>
  <n-modal class="modal-users-create" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <h3>Создать пользователя</h3>
        <n-input title="Логин" v-bind="$form.input('username')" />
        <n-input title="ФИО" v-bind="$form.input('fullName')" />
        <n-input title="Пароль" type="password" v-bind="$form.input('password')" />
        <n-select title="Роль" :data="roles" v-bind="$form.input('role', 'select')" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button color="success" type="submit">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalUsersCreate',
  data() {
    return {
      roles: [],
    }
  },
  created() {
    this.loadRoles()
    this.$form.init({
      username: '',
      fullName: '',
      password: '',
      role: '',
    })
    this.$form.rules({
      username: [ 'required', ],
      fullName: [ 'required', ],
      password: [ 'required', ],
      role: [ 'required', ],
    })
  },
  methods: {
    loadRoles() {
      this.$var('load', true)
      $api.auth.roles.get().then((response) => {
        this.roles = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          ...this.$form.get(),
          roleName: this.$form.get('role.name'),
        }
        $api.auth.users.create(data).then((response) => {
          this.$emit('reload')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-users-create {
}
</style>
