<template>
  <n-form class="set-customer" style="width: 500px" @submit="submit">
    <n-loader :loading="$var('load')" />
    <n-items>
      <n-select title="Заявка *" :data="orders" v-bind="$form.input('order', 'select')" option-title="number" selected-title="number" />
    </n-items>
    <div class="footer-buttons">
      <n-button @click="$emit('close')">Отменить</n-button>
      <n-button type="submit" color="success">Сохранить</n-button>
    </div>
  </n-form>
</template>

<script>
export default {
  name: 'SetCustomer',
  props: {
    order: { type: Object, default: null, },
    letterId: { type: Number, default: 1, },
  },
  data: () => ({
    orders: [],
    customers: [],
  }),
  created() {
    this.load()
    this.$form.init({
      order: null,
    })
    this.$form.rules({
      order: [ 'required', ],
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      const apis = [
        $api.app.orders.get(),
      ]
      Promise.all(apis).then((responses) => {
        this.orders = responses[0].data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          ownerId: this.$form.get('order.customerId'),
          applicationId: this.$form.get('order.id'),
        }
        $api.email.letters.type(this.letterId, 'customer', data).then((response) => {
          this.$emit('submit', response.data.content)
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.set-customer {
}
</style>
