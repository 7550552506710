var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-info"},[_c('n-loader',{attrs:{"loading":_vm.$var('load')}}),_c('c-info-table',{attrs:{"data":_vm.info},scopedSlots:_vm._u([{key:"image",fn:function(){return [(_vm.product.image)?_c('img',{staticClass:"product-image",attrs:{"src":_vm.product.image,"alt":"image"}}):_c('span',[_vm._v("-")])]},proxy:true}])}),_c('h2',[_vm._v("История заявок")]),_c('n-table',{attrs:{"data":_vm.product.calculations,"columns":_vm.columns},scopedSlots:_vm._u([{key:"number",fn:function(ref){
var item = ref.item;
return [_c('n-link',{attrs:{"to":{name: 'orders.index', params: {id: _vm.product.application.id}, query: {page: 'products', 'order-product': _vm.product.id, tab: 'calc' }}}},[_vm._v(" "+_vm._s(_vm.product.application.number)+" ")])]}},{key:"clientCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n.numberFormat(item.pieceCostCustomer))+" "+_vm._s(item.commercialCurrency)+" ")]}},{key:"photo",fn:function(ref){
var item = ref.item;
return [(item.priceOffer.image)?_c('img',{staticStyle:{"max-height":"50px"},attrs:{"src":item.priceOffer.image,"alt":"image"}}):_c('span')]}},{key:"providerCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n.numberFormat(item.priceOffer.price))+" "+_vm._s(item.priceOffer.currency)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }