<template>
  <div class="index-page container">
    <n-card :loading="$var('loadFolders')" class="folders-card">
      <div>
        <div v-for="email in emails" :key="email.id">
          <h3>
            {{ email.email }}
            <n-button icon="sync" flat round style="font-size: .6em;" @click="sync(email.id)" />
          </h3>
          <div v-for="folder in email.folders" :key="folder.id">
            <router-link :to="{ name: 'inbox.list', params: { folder: folder.id, }, }">{{ folder.title }}</router-link>
          </div>
        </div>
      </div>
    </n-card>
    <n-card class="letters-card">
      <n-data-component ref="data" :api="api" :data.sync="letters" :loading.sync="loading" v-bind="params" :size="200" />
      <n-divide class="table-tools">
        <div>
          <div class="filters">
            <n-search :value="params.filter.search" placeholder="Поиск по письмам (заголовок, содержание, номер заявки)" @update:value="search" />
          </div>
        </div>
        <div>
          <n-link :to="{name: 'inbox.create'}">
            <n-button>Новое письмо</n-button>
          </n-link>
        </div>
      </n-divide>

      <n-table :data="letters" :columns="columns" :loading="loading">
        <template #subject="{item}">
          <span :class="[ { seen: !item.isSeen, }, ]">{{ item.subject }}</span>
          <n-dropdown v-if="item.files.length" :data="item.files" item-title="name">
            <div><n-icon icon="file" /> {{ item.files.length }}</div>
          </n-dropdown>
        </template>
        <template #application="{item}">
          <n-link v-if="item.application" :to="{ name: 'orders.index', params: { id: item.applicationId, },}">{{ item.application.number }}</n-link>
        </template>
        <template #to="{item}">
          <div>
            <div v-for="r in item.recipients" :key="r.id" class="email">
              <span v-if="r.type !== 'to'">{{ r.type }}: </span>
              {{ r.title }}
            </div>
          </div>
        </template>
        <template #from="{item}">
          <div class="email">{{ item.from }}</div>
        </template>
        <template #createdAt="{item}">
          {{ $app.date.format(item.createdAt) }}
        </template>
        <template #files="{item}">
          <template v-if="item.files.length">
            <div v-for="file in item.files" :key="file.id" class="file">
              <a :href="file.src" target="_blank">{{ file.name }}</a>
            </div>
          </template>
          <span v-else>
            -
          </span>
        </template>
        <template #tools="{item}">
          <div class="tools">
            <n-button icon="trash" round color="danger" @click="remove(item.id)" />
            <n-dropdown :data="getFoldersForMove(item)" style="display: inline-block;" @click="(v) => move(item.id, v.id)">
              <n-button icon="archive" round />
            </n-dropdown>
            <n-link :to="{name: 'inbox.letter', params: { id: item.id, }}">
              <n-button icon="eye" round />
            </n-link>
          </div>
        </template>
      </n-table>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      emails: [],
      letters: [],
      loading: false,
      params: {
        filter: {},
      },
      columns: [
        { name: 'createdAt', title: 'Дата', },
        { name: 'application', title: 'Заявка', },
        { name: 'subject', title: 'Тема письма', },
        { name: 'from', title: 'От', },
        { name: 'to', title: 'Кому', },
        // { name: 'files', title: 'Вложения', },
        { name: 'tools', title: '', width: '120px', },
      ],
    }
  },
  computed: {
    folderId() {
      return this.$route.params.folder
    },
    api() {
      if (this.folderId) {
        return () => $api.email.letters.getByFolder(this.folderId).with('files').with('recipients').with('application').sort('createdAt,desc').size(200)
      } else {
        return null
      }
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    search(value) {
      if (value) {
        this.params = {
          ...this.params,
          filter: {
            ...this.params.filter,
            search: value,
          },
        }
      } else {
        this.params = {
          filter: {},
        }
      }
    },
    getFoldersForMove(letter) {
      const folders = $n.find(this.emails, [ 'id', letter.emailId, ])?.folders
      if (folders) {
        return $n.filter(folders, (item) => item.name !== letter.folderName)
      }
      return []
    },
    load() {
      this.$var('loadFolders', true)
      const apis = [
        $api.my.emails().query({ 'with[folders][sort][]': 'type,asc', }).sort([ 'createdAt,asc', ]),
      ]
      Promise.all(apis).then((response) => {
        const emails = response[0].data.content
        $n.each(emails, (i) => {
          i.folders = $n.sortBy(i.folders, [ 'type', ])
        })
        this.emails = emails
      }).finally(() => {
        this.$var('loadFolders', false)
      })
    },
    sync(id) {
      this.$var('loadFolders', true)
      $api.email.emails.sync(id).then((response) => {
        this.load()
        if (this.folderId) this.$refs.data.reload()
      }).finally(() => {
        this.$var('loadFolders', false)
      })
    },
    move(letterId, folderId) {
      this.$var('loadLetters', true)
      $api.email.letters.move(letterId, folderId).then((response) => {
        if (this.folderId) this.$refs.data.reload()
      }).finally(() => {
        this.$var('loadLetters', false)
      })
    },
    remove(letterId) {
      this.$var('loadLetters', true)
      $api.email.letters.delete(letterId).then((response) => {
        if (this.folderId) this.$refs.data.reload()
      }).finally(() => {
        this.$var('loadLetters', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.index-page {
  display: flex;

  .filters {
    width: 400px;
    margin: 20px 0;
    &>* {
      margin: 10px 0;
    }
  }
  .folders-card {
    min-width: 250px;
    .router-link-active {
      font-weight: bold;
    }
  }
  .letters-card {
    width: 100%;
    font-size: .7em;
  }

  .email {
    margin-bottom: 5px;
  }

  .file {
    margin-bottom: 5px;
  }

  .tools {
    &>* {
      margin-left: 5px;
    }
  }

  .seen {
    font-weight: bold;
    color: var(--primary);
  }
}
</style>
