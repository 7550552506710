<template>
  <n-modal class="modal-edit" :loading="$var('load')" @close="$emit('close')">
    <h2>Редактирование информации</h2>
    <n-form @submit="submit">
      <n-items>
        <n-input v-bind="$form.input('fullName')" title="ФИО" />
        <n-input v-bind="$form.input('username')" title="Логин" />
        <n-input v-bind="$form.input('email')" title="Почта для связи" />
        <n-input v-bind="$form.input('phone')" title="Телефон" />
        <n-input v-bind="$form.input('password')" title="Пароль" />
        <n-select title="Роль" :data="roles" v-bind="$form.input('role', 'select')" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button type="submit" color="success">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalEdit',
  props: {
    user: { type: Object, required: true, },
  },
  data() {
    return {
      roles: [],
    }
  },
  created() {
    this.load()
    this.$form.init(this.user)
    this.$form.rules({
      fullName: [ 'required', ],
      username: [ 'required', ],
      role: [ 'required', ],
      phone: [ 'phone', ],
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.auth.roles.get().then((response) => {
        this.roles = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          ...this.$form.get(),
          roleName: this.$form.get('role').name,
        }
        $api.auth.users.edit(this.user.id, data).then(() => {
          $api.auth.info().then((response) => {
            $app.auth.grants(response.data.content.grants)
            this.$emit('reload')
            this.$emit('close')
          }).finally(() => {
            this.$var('load', false)
          })
        }).catch(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-edit {
  --n-modal-width: 600px;
}
</style>
