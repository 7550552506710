var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-page container"},[_c('n-card',{staticClass:"folders-card",attrs:{"loading":_vm.$var('loadFolders')}},[_c('div',_vm._l((_vm.emails),function(email){return _c('div',{key:email.id},[_c('h3',[_vm._v(" "+_vm._s(email.email)+" "),_c('n-button',{staticStyle:{"font-size":".6em"},attrs:{"icon":"sync","flat":"","round":""},on:{"click":function($event){return _vm.sync(email.id)}}})],1),_vm._l((email.folders),function(folder){return _c('div',{key:folder.id},[_c('router-link',{attrs:{"to":{ name: 'inbox.list', params: { folder: folder.id, }, }}},[_vm._v(_vm._s(folder.title))])],1)})],2)}),0)]),_c('n-card',{staticClass:"letters-card"},[_c('n-data-component',_vm._b({ref:"data",attrs:{"api":_vm.api,"data":_vm.letters,"loading":_vm.loading,"size":200},on:{"update:data":function($event){_vm.letters=$event},"update:loading":function($event){_vm.loading=$event}}},'n-data-component',_vm.params,false)),_c('n-divide',{staticClass:"table-tools"},[_c('div',[_c('div',{staticClass:"filters"},[_c('n-search',{attrs:{"value":_vm.params.filter.search,"placeholder":"Поиск по письмам (заголовок, содержание, номер заявки)"},on:{"update:value":_vm.search}})],1)]),_c('div',[_c('n-link',{attrs:{"to":{name: 'inbox.create'}}},[_c('n-button',[_vm._v("Новое письмо")])],1)],1)]),_c('n-table',{attrs:{"data":_vm.letters,"columns":_vm.columns,"loading":_vm.loading},scopedSlots:_vm._u([{key:"subject",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[ { seen: !item.isSeen, } ]},[_vm._v(_vm._s(item.subject))]),(item.files.length)?_c('n-dropdown',{attrs:{"data":item.files,"item-title":"name"}},[_c('div',[_c('n-icon',{attrs:{"icon":"file"}}),_vm._v(" "+_vm._s(item.files.length))],1)]):_vm._e()]}},{key:"application",fn:function(ref){
var item = ref.item;
return [(item.application)?_c('n-link',{attrs:{"to":{ name: 'orders.index', params: { id: item.applicationId, },}}},[_vm._v(_vm._s(item.application.number))]):_vm._e()]}},{key:"to",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.recipients),function(r){return _c('div',{key:r.id,staticClass:"email"},[(r.type !== 'to')?_c('span',[_vm._v(_vm._s(r.type)+": ")]):_vm._e(),_vm._v(" "+_vm._s(r.title)+" ")])}),0)]}},{key:"from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"email"},[_vm._v(_vm._s(item.from))])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$app.date.format(item.createdAt))+" ")]}},{key:"files",fn:function(ref){
var item = ref.item;
return [(item.files.length)?_vm._l((item.files),function(file){return _c('div',{key:file.id,staticClass:"file"},[_c('a',{attrs:{"href":file.src,"target":"_blank"}},[_vm._v(_vm._s(file.name))])])}):_c('span',[_vm._v(" - ")])]}},{key:"tools",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tools"},[_c('n-button',{attrs:{"icon":"trash","round":"","color":"danger"},on:{"click":function($event){return _vm.remove(item.id)}}}),_c('n-dropdown',{staticStyle:{"display":"inline-block"},attrs:{"data":_vm.getFoldersForMove(item)},on:{"click":function (v) { return _vm.move(item.id, v.id); }}},[_c('n-button',{attrs:{"icon":"archive","round":""}})],1),_c('n-link',{attrs:{"to":{name: 'inbox.letter', params: { id: item.id, }}}},[_c('n-button',{attrs:{"icon":"eye","round":""}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }