<template>
  <div class="page-providers-list">
    <n-card>
      <div ref="sds" class="header-container">
        <div class="table-tools">
          <n-divide>
            <div></div>
            <div><n-button @click="$var('create', true)">Новый поставщик</n-button></div>
          </n-divide>
        </div>
      </div>

      <c-providers-list ref="list" :api="api" />
    </n-card>

    <modal-providers-create v-if="$var('create')" @reload="$refs.list.reload()" @close="$var('create', false)" />
  </div>
</template>

<script>
import ModalProvidersCreate from './Create'

export default {
  name: 'PageProvidersList',
  components: { ModalProvidersCreate, },
  computed: {
    api() {
      return () => $api.app.providers.get().with('categories').with('contacts').with('orderCount').with('callCount').size(200)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-providers-list {
  .contact, .categories {
    font-size: .9em;
    span {
      opacity: .8;
    }
  }
}
</style>
