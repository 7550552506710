var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-history"},[_c('n-loader',{attrs:{"loading":_vm.$var('load')}}),_c('n-table',{attrs:{"data":_vm.orders,"columns":_vm.columns},scopedSlots:_vm._u([{key:"number",fn:function(ref){
var item = ref.item;
return [_c('n-link',{attrs:{"to":{ name: 'orders.index', params: {id: item.id} }}},[_c('span',[_vm._v(_vm._s(item.number || item.id))])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$n.status(item.status).title))]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$app.date.format(item.startedAt)))]}},{key:"endedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$app.date.format(item.endedAt, 'date')))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }