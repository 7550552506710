<template>
  <n-modal class="product-tabs" :loading="$var('load')" @close="$emit('close')">
    <n-tabs state :data="tabs" name="page" />
    <n-tabs-content name="page">
      <template #info>
        <tab-info v-if="product.id" :product="product" />
      </template>
      <template #history>
        <tab-history v-if="product.id" :product="product" />
      </template>
    </n-tabs-content>
  </n-modal>
</template>

<script>
import TabInfo from './tab-info/Index'
import TabHistory from './tab-history/Index'

export default {
  name: 'CardProduct',
  components: {
    TabInfo,
    TabHistory,
  },
  data() {
    return {
      product: {},
      tabs: [
        { name: 'info', title: 'Информация о товаре', },
        // { name: 'history', title: 'История заявок', },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.query.product
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.products.get(this.id).with('application').with('category').with('calculations').then((response) => {
        this.product = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-tabs {
  --n-modal-width: 1100px;
}
</style>
