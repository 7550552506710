<template>
  <div class="page-products-list">
    <n-card :loading="loading">
      <div class="filters">
        <n-search :value="params.filter.byNames" placeholder="Поиск (название, артикул, производитель)" @update:value="search" />
        <n-search :value="params.filter.app" placeholder="Поиск по заявке (номер, PO) " @update:value="searchNumber" />
        <n-select placeholder="Категория" :data="categories" :value.sync="category" item-value="id" @update:value="selectCategory" />
      </div>
      <n-data-component ref="data" :api="api" :data.sync="products" :loading.sync="loading" v-bind="params" />

      <n-table :data="products" :columns="columns">
        <template #image="{item}">
          <img v-if="item.image" class="product-image" :src="item.image" alt="image" />
          <span v-else></span>
        </template>
        <template #tools="{item}">
          <n-link :to="{ query: { ...$route.query, product: item.id, },}">
            <n-button icon="arrow-right" round />
          </n-link>
        </template>
      </n-table>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'PageProductsList',
  data: () => ({
    products: [],
    loading: false,
    columns: [
      { name: 'tools', title: '', },
      { name: 'image', title: 'Фото', },
      { name: 'title', title: 'Название товара', },
      { name: 'code', title: 'StockCode', },
      { name: 'category.title', title: 'Категория', },
    ],
    params: {
      filter: {},
    },
    category: { title: 'Все категории', id: undefined, },
    categories: [],
  }),
  computed: {
    api() {
      return () => $api.app.products.get().with('category').all()
    },
  },
  created() {
    this.loadCategories()
  },
  methods: {
    loadCategories() {
      $api.app.categories.get().then((response) => {
        this.categories = [
          { title: 'Все категории', id: undefined, },
          ...response.data.content,
        ]
      })
    },
    selectCategory(value) {
      this.category = value
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          categoryId: value.id,
        },
      }
    },
    search(value) {
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          byNames: value,
        },
      }
    },
    searchNumber(value) {
      if (value && !value.replace('search:', '')) {
        value = undefined
      }
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          app: value,
        },
      }
    },
    searchByProduct(value) {
      if (value && !value.replace('search:', '')) {
        value = undefined
      }
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          byProduct: value,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .page-products-list {
    .product-image {
      max-height: 50px;
    }
    .filters {
      max-width: 400px;
      margin: 20px 0;
      &>* {
        margin: 10px 0;
      }
    }
  }
</style>
