<template>
  <div class="block-documents">
    <n-loader :loading="$var('load')" />
    <div class="header">
      <div class="title">{{ title }}</div>
      <div v-if="editable" class="tools">
        <n-button icon="plus" flat @click="$var('add', true)" />
      </div>
    </div>
    <div v-for="item in documents" :key="item.id" class="document">
      <a :href="'https://docs.google.com/gview?url='+item.document" target="_blank" class="content">
        <n-icon :icon="fileIcon(item.document).icon" :style="{ color: fileIcon(item.document).color, }" />
        <div class="title">
          {{ item.title }}
        </div>
      </a>
      <div class="tools">
        <n-link v-if="editable && item.type === 'commercialOffer'" :to="sendKpRoute(item)"><n-button icon="share" flat /></n-link>
        <a :href="item.document" target="_blank"><n-button icon="download" flat /></a>
        <n-button v-if="editable" icon="trash" flat @click.stop="remove(item.id)" />
      </div>
    </div>

    <modal-add-document v-if="$var('add')" :order="order" @submit="$emit('reload')" @close="$var('add', false)" />
  </div>
</template>

<script>
import ModalAddDocument from './info-table/AddDocument'

export default {
  name: 'BlockDocuments',
  components: { ModalAddDocument, },
  props: {
    order: { type: Object, default: () => ({}), },
    documents: { type: Array, default: () => [], },
    editable: { type: Boolean, default: false, },
    title: { type: String, default: '', },
  },
  data() {
    return {
      types: {
        doc: { icon: 'file-word', color: '#2b5797', },
        docx: { icon: 'file-word', color: '#2b5797', },
        xls: { icon: 'file-excel', color: '#1f7246', },
        xlsx: { icon: 'file-excel', color: '#1f7246', },
        pdf: { icon: 'file-pdf', color: '#d83b19', },
        txt: { icon: 'file-txt', color: '#777', },
      },
    }
  },
  methods: {
    sendKpRoute(item) {
      return { name: 'inbox.create', query: { orderId: this.order.id, orderType: 'customer', kp: item.id, }, }
    },
    fileIcon(path) {
      const parts = path.split('.')
      const ext = parts[parts.length - 1]
      return this.types[ext] || this.types['txt']
    },
    remove(id) {
      this.$var('load', true)
      $api.app.document.delete(id).then(() => {
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block-documents {
  position: relative;
  min-width: 300px;
  a {
    color: inherit;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 15px;
    .title {
      width: 100%;
      font-weight: 500;
      line-height: 2;
    }
    .tools {
      font-size: .8em;
    }
  }
  .document {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 5px;
    background: #fafafa;
    text-decoration: none;
    &>.content {
      display: flex;
      align-items: center;
      width: 100%;
      &>.n-icon {
        margin-right: 15px;
        font-size: 2em;
        color: #003399;
      }
      &>.title {
        color: #666;
        width: 100%;
        font-size: .9em;
      }
    }
    .tools {
      display: flex;
      text-align: right;
      opacity: .5;
      transition: opacity 300ms;
      font-size: .8em;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
