<template>
  <n-modal class="modal-contact" :loading="$var('load')" @close="$emit('close')">
    <h3>{{ modalTitle }}</h3>
    <n-form @submit="submit">
      <n-items>
        <n-input title="ФИО" v-bind="$form.input('title')" />
        <n-input title="Телефон" v-bind="$form.input('phone')" />
        <n-input title="Почта *" v-bind="$form.input('email')" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button type="submit" color="success">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalContact',
  props: {
    contact: { type: Object, required: true, },
    type: { type: String, required: true, }, // customer or supplier
    model: { type: Object, default: () => ({}), },
  },
  data: () => ({
  }),
  computed: {
    id() {
      return this.$route.query.customer
    },
    modalTitle() {
      return this.isCreating ? 'Создание контакта' : 'Редактирование контакта'
    },
    isCreating() {
      return !this.contact.id
    },
  },
  mounted() {
    if (this.isCreating) {
      this.$form.init({
        title: '',
        phone: '',
        email: '',
      })
    } else {
      this.$form.init(this.contact)
    }
    this.$form.rules({
      email: [ 'email', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        let api
        if (this.isCreating) {
          const types = { provider: 'supplier', customer: 'customer', }
          api = $api.app.contacts.create({
            ...this.$form.get(),
            companyId: this.model.id,
            companyType: types[this.type],
          })
        } else {
          api = $api.app.contacts.edit(this.contact.id, this.$form.get())
        }

        this.$var('load', true)
        api.then(() => {
          this.$emit('close')
          this.$emit('reload')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .modal-contact {
    --n-modal-width: 600px;

    .create-button {
      margin-left: 30px
    }
    .form {
      text-align: center
    }
    .buttons-container {
      display:flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 30px
    }
  }
</style>
