<template>
  <n-modal class="modal-product" :loading="$var('load') || $var('loadCategories')" @close="$emit('close')">
    <n-tabs :data="tabs" name="tab" state />
    <n-tabs-content name="tab">
      <template #info>
        <tab-product-info :order="order" :product="product" :editable="editable" :loading="$var('load')" @reload="reload" @close="$emit('close')" />
      </template>
      <template #calc>
        <tab-product-calc :order="order" :product="product" :editable="editable" :loading="$var('load')" @reload="reload" @close="$emit('close')" />
      </template>
    </n-tabs-content>
  </n-modal>
</template>

<script>
import TabProductInfo from './TabInfo'
import TabProductCalc from './TabCalc'

export default {
  name: 'ModalProduct',
  components: { TabProductCalc, TabProductInfo, },
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      product: {},
    }
  },
  computed: {
    productId() {
      return this.$route.query['order-product']
    },
    tabs() {
      const tabs = [
        { name: 'info', title: 'Описание товара', },
      ]
      if (this.productId > 0 && ![ 'created', 'approving', ].includes(this.order.status)) {
        tabs.push({ name: 'calc', title: 'Расчет цены', })
      }
      return tabs
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      if (this.productId > 0) {
        this.$var('load', true)
        $api.app.products.get(this.productId).with('category').with('calculation').then((response) => {
          this.product = response.data.content
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    reload() {
      this.load()
      this.$emit('reload')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-product {
  --n-modal-width: 1100px;
  &::v-deep>.n-wrapper {
    top: 5%;
    margin-bottom: 5%;
  }
}
</style>
