<template>
  <div class="block-info-table">
    <div v-if="editable" class="tools">
      <n-button icon="pen" flat @click="() => $var('edit', true)" />
    </div>
    <table>
      <tr>
        <td>Номер заявки</td>
        <td>{{ order && order.number }}</td>
      </tr>
      <tr>
        <td>Номер PO</td>
        <td>{{ order && order.poNumber }}</td>
      </tr>
      <tr>
        <td>Заказчик</td>
        <td>{{ order.customer && order.customer.title }}</td>
      </tr>
      <tr>
        <td>Баер заказчика</td>
        <td>{{ order.contact && order.contact.title }}</td>
      </tr>
      <tr>
        <td>Дата обращения</td>
        <td>{{ order.createdAt && $app.date.format(order.createdAt, 'date') }}</td>
      </tr>
      <tr>
        <td>Крайний срок подачи предложения</td>
        <td>{{ order.endedAt && $app.date.format(order.endedAt, 'date') }}</td>
      </tr>
      <tr>
        <td>Описание</td>
        <td v-html="$app.secure.clean(order.description)" />
      </tr>
      <tr>
        <td>Кому</td>
        <td>{{ order && order.to }}</td>
      </tr>
      <tr>
        <td>Копия</td>
        <td>{{ order && order.copy }}</td>
      </tr>
      <tr>
        <td>Тема</td>
        <td>{{ order && order.subject }}</td>
      </tr>
      <tr>
        <td>Валюта КП</td>
        <td>{{ order && order.currency }}</td>
      </tr>
      <tr>
        <td>RFQ</td>
        <td>{{ order && order.rfq }}</td>
      </tr>
      <tr>
        <td>Incoterms</td>
        <td>{{ order && order.incoterms }}</td>
      </tr>
    </table>

    <modal-edit-order v-if="$var('edit')" :order="order" @submit="$emit('reload')" @close="$var('edit', false)" />
  </div>
</template>

<script>
import ModalEditOrder from './info-table/ModalEditOrder'

export default {
  name: 'BlockInfoTable',
  components: { ModalEditOrder, },
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
}
</script>

<style lang="scss" scoped>
.block-info-table {
  position: relative;
  margin-right: 20px;
  width: 100%;

  .tools {
    position: absolute;
    right: 0;
    top: 0;
  }
  table {
    width: 100%;
  }
  td {
    padding: 10px 15px;
    border-bottom: 2px solid #f6f6f6;

    &:first-child {
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
    &:last-child {
      width: 100%;
    }
  }
}
</style>
