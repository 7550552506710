<template>
  <n-modal class="modal-sign" :loading="$var('load')" @close="$emit('close')">
    <n-form @submit="submit">
      <n-items>
        <n-input title="Название" v-bind="$form.input('title')" />
        <n-textarea title="Подпись" v-bind="$form.input('content')" />
        <n-button type="submit">Сохранить</n-button>
      </n-items>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalSign',
  props: {
    sign: { type: [ Object, Boolean, ], default: true, },
  },
  data() {
    return {

    }
  },
  created() {
    if (this.sign === true) {
      this.$form.init({
        title: '',
        content: '',
      })
    } else {
      this.$form.init(this.sign)
    }
    this.$form.rules({
      title: [ 'required', ],
      content: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const api = this.sign === true ?
          $api.auth.signs.create($app.auth.user().id, this.$form.get()) :
          $api.auth.signs.edit(this.sign.id, this.$form.get())
        api.then((response) => {
          this.$emit('reload')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-sign {
  --n-modal-width: 800px;

}
</style>
