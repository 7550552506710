var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-list"},[_c('n-card',[_c('div',{staticClass:"filters"},[_c('n-search',{attrs:{"value":_vm.params.filter.number,"placeholder":"Поиск по заявке (номер, PO) "},on:{"update:value":_vm.search}}),_c('n-select',{attrs:{"placeholder":"Менеджер","data":_vm.managers,"value":_vm.manager,"item-value":"id","option-title":"fullName","selected-title":"fullName"},on:{"update:value":[function($event){_vm.manager=$event},_vm.selectManager]}}),_c('n-select',{attrs:{"placeholder":"Статус","data":_vm.statuses,"value":_vm.status,"item-value":"name"},on:{"update:value":[function($event){_vm.status=$event},_vm.selectStatus]}})],1),_c('n-data-component',_vm._b({ref:"data",attrs:{"api":_vm.api,"data":_vm.orders,"loading":_vm.loading},on:{"update:data":function($event){_vm.orders=$event},"update:loading":function($event){_vm.loading=$event}}},'n-data-component',_vm.params,false)),_c('n-table',{attrs:{"data":_vm.orders,"columns":_vm.columns,"loading":_vm.loading},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$app.date.format(item.createdAt))+" ")]}},{key:"endedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$app.date.format(item.endedAt, 'date'))+" ")]}},{key:"headerCell",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"header-cell"},[_vm._v(" "+_vm._s(column.title)+" "),(column.sort)?_c('n-button',{attrs:{"flat":"","round":"","icon":"arrows-alt-v"},on:{"click":function($event){return _vm.sort(column.name)}}}):_vm._e()],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n.status(item.status).title)+" ")]}},{key:"tools",fn:function(ref){
var item = ref.item;
return [_c('n-link',{attrs:{"to":{name: 'orders.index', params: { id: item.id, }}}},[_c('n-button',{attrs:{"icon":"eye","round":""}})],1)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('n-button',{attrs:{"flat":"","round":"","icon":"trash"},on:{"click":function($event){return _vm.deleteOrder(item.id)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }