<template>
  <div class="block-statuses">
<!--    <n-button v-if="['calculation', 'waiting'].includes(order.status)" color="primary">Отправить КП</n-button>-->
    <n-button v-if="!$n.closedStatus(order.status)" @click="$var('change', true)">Сменить статус</n-button>

    <n-modal v-if="$var('change')" :loading="$var('load') || loading" @close="$var('change', false)">
      <n-form @submit="submit">
        <n-items>
          <n-select title="Статус" :data="statuses" v-bind="$form.input('status', 'select')" item-value="name" />
          <n-select v-if="showManagers" title="Менеджер" :data="managers" v-bind="$form.input('manager', 'select')"
                    option-title="fullName" selected-title="fullName" item-value="id" />
          <n-button type="submit">Сменить статус</n-button>
        </n-items>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'BlockStatuses',
  props: {
    order: { type: Object, default: () => ({}), },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      managers: [],
    }
  },
  computed: {
    statuses() {
      return $n.status('all')
      // const statuses = []
      // if (this.order.status === 'created') {
      //   statuses.push($n.status('approving'))
      // } else if (this.order.status === 'approving') {
      //   statuses.push($n.status('calculation'))
      // } else if (this.order.status === 'calculation') {
      //   statuses.push($n.status('waiting'))
      // } else if (this.order.status === 'waiting') {
      //   statuses.push($n.status('finished'))
      //   statuses.push($n.status('failed'))
      // }
      // if (!$n.closedStatus(this.order.status)) {
      //   statuses.push($n.status('canceled'))
      // }
      //
      // return statuses
    },
    showManagers() {
      const status = this.$form.get('status') || {}
      return status.name === 'calculation'
    },
  },
  watch: {
    'form_inputs.default.status'(value) {
      if (value?.name === 'calculation') {
        this.loadManagers()
        this.$form.rules({
          status: [ 'required', ],
          manager: [ 'required', ],
        })
      } else {
        this.$form.rules({
          status: [ 'required', ],
        })
      }
    },
  },
  created() {
    if (this.order.status === 'approving') {
      this.loadManagers()
    }
    this.$form.init({
      status: null,
      manager: null,

    })
    this.$form.rules({
      status: [ 'required', ],
    })
  },
  methods: {
    loadManagers() {
      this.$var('load', true)
      $api.auth.users.get().then((response) => {
        this.managers = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$emit('update:loading', true)
        const data = {
          status: this.$form.get('status').name,
        }
        if (this.showManagers) {
          data.managerId = this.$form.get('manager').id
        }
        $api.app.orders.edit(this.order.id, data).then((response) => {
          this.$emit('reload')
          this.$var('change', false)
          this.$form.init({
            status: null,
            manager: null,
          })
        }).catch(() => {
          this.$emit('update:loading', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.block-statuses {
  &>* {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
