var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-offers"},[_c('n-loader',{attrs:{"loading":_vm.$var('load')}}),(_vm.offers.length !== 0 || this.$var('load'))?_c('div',{staticClass:"price-offers-table"},[_c('n-table',{attrs:{"data":_vm.offers,"columns":_vm.columns},scopedSlots:_vm._u([{key:"number",fn:function(ref){
var item = ref.item;
return [_c('n-link',{attrs:{"to":{ name: 'orders.index', params: {id: item.applicationId}, query: { page: 'products', 'order-product': item.product.id, tab: 'calc', }, }}},[_vm._v(" "+_vm._s(item.application.number)+" ")])]}},{key:"image",fn:function(ref){
var item = ref.item;
return [_c('n-image',{attrs:{"src":item.image,"centered":""}})]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$app.secure.clean(item.product.description))}})]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n.numberFormat(item.price))+" "+_vm._s(item.currency)+" ")]}},{key:"kpPrice",fn:function(ref){
var item = ref.item;
return [(item.calculation)?_c('span',[_vm._v(_vm._s(_vm.$n.numberFormat(item.calculation.pieceCostCustomer))+" "+_vm._s(item.calculation.commercialCurrency))]):_c('span',[_vm._v("-")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n.status(item.application.status).title)+" ")]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$app.date.format(item.createdAt))+" ")]}}],null,false,2259168072)})],1):_c('div',[_c('h3',{staticClass:"empty-message"},[_vm._v("У поставщика нет Ценовых Предложений...")])]),(_vm.$var('create'))?_c('n-modal',{on:{"close":function($event){return _vm.$var('create', false)}}},[_c('div',{staticClass:"modal-container"},[_c('h3',[_vm._v(" Информация о рассчетах ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }