<template>
  <div class="tab-history">
    <n-loader :loading="$var('load')" />
    
    <n-table :data="orders" :columns="columns">
      <template #number="{item}">
        <n-link :to="{ name: 'orders.index', params: {id: item.id} }">
          <span>{{ item.number || item.id }}</span>
        </n-link>
      </template>
      <template #status="{item}">{{ $n.status(item.status).title }}</template>
      <template #createdAt="{item}">{{ $app.date.format(item.startedAt) }}</template>
      <template #endedAt="{item}">{{ $app.date.format(item.endedAt, 'date') }}</template>
    </n-table>
  </div>
</template>

<script>
export default {
  name: 'TabHistory',
  props: {
    customer: { type: Object, required: true, },
  },
  data: () => ({
    orders: [],
    columns: [
      { name: 'number', title: '№ Заявки', },
      { name: 'status', title: 'Статус', },
      { name: 'productsCount', title: 'Кол-во позиций', },
      { name: 'manager.fullName', title: 'Менеджер', },
      { name: 'contact.title', title: 'Баер', },
      { name: 'endedAt', title: 'Крайний срок подачи предложения', },
      { name: 'createdAt', title: 'Дата создания', },
    ],
  }),
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.customers.getOrders(this.customer.id).with('manager').with('contact').with('productsCount').all().then((response) => {
        this.orders = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.tab-history {
  position: relative;
  padding-top: 20px;
}

</style>
