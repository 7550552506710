<template>
  <n-modal class="card-customer" :loading="$var('load')" @close="$emit('close')">
    <n-tabs state :data="tabs" name="tab" />
    <n-tabs-content name="tab">
      <template #info>
        <tab-info v-if="customer.id" :customer="customer" @reload="load" />
      </template>
      <template #history>
        <tab-history v-if="customer.id" :customer="customer" />
      </template>
      <template #customer>
        <c-letters-history :loading="$var('load')" :owner-id="Number(id)" type="customer" />
      </template>
    </n-tabs-content>
  </n-modal>
</template>

<script>
import TabInfo from './tab-info/Index'
import TabHistory from './tab-history/Index'

export default {
  name: 'CardCustomer',
  components: { TabInfo, TabHistory, },
  data() {
    return {
      customer: {},
      tabs: [
        { name: 'info', title: 'Информация о закачике', },
        { name: 'history', title: 'История заявок', },
        { name: 'customer', title: 'Переписка с заказчиком', },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.query.customer
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.customers.get(this.id).with('categories').with('contacts').then((response) => {
        this.customer = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-customer {
  --n-modal-width: 1300px;

  &::v-deep>.n-wrapper>.n-card>.content {
    min-height: 400px;
  }
}
</style>
