<template>
  <div class="tab-products">
    <block-products :order="order" :editable="editable" @reload="$emit('reload')" />
    <div v-if="!['created', 'approving'].includes(order.status)" class="tab-content">
      <div class="controls">
        <block-documents :order="order" :documents="documents" title="Варианты коммерческого предложения" :editable="editable" @reload="$emit('reload')" />
        <n-button v-if="editable" @click="$var('kp', true)">Сформировать файл коммерческого предложения</n-button>
      </div>
      <block-info :order="order" :editable="editable" @reload="$emit('reload')" />
    </div>

    <modal-create-kp v-if="$var('kp')" :order="order" @close="$var('kp', false)" @reload="$emit('reload')" />
  </div>
</template>

<script>
import BlockInfo from './Info'
import BlockProducts from './BlockProducts'
import BlockDocuments from '../info/BlockDocuments'
import ModalCreateKp from './ModalCreateKp'

export default {
  name: 'TabProducts',
  components: { ModalCreateKp, BlockDocuments, BlockProducts, BlockInfo, },
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  computed: {
    documents() {
      return $n.filter(this.order.documents, [ 'type', 'commercialOffer', ])
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-products {
  .tab-content {
    display: flex;
    &>*:first-child {
      padding-right: 20px;
    }
  }
  .controls {
    width: 40%;
    padding-top: 20px;
    .block-documents {
      margin-bottom: 15px;
    }
  }
  .block-info {
    width: 60%;
  }
}
</style>
