<template>
  <div class="tab-info">
    <n-loader :loading="$var('load') && !loading" />
    <div class="header">
      <div class="edit-button">
        <h4>Информация</h4>
        <n-button icon="pencil-alt" round @click="$var('edit', true)" />
      </div>
    </div>

    <c-info-table :data="info" />

    <c-contacts type="provider" :model="provider" />

    <modal-edit v-if="$var('edit')" :provider="provider" @reload="$emit('reload')" @close="$var('edit', false)" />
  </div>
</template>

<script>
import ModalEdit from './ModalEdit'

export default {
  name: 'TabInfo',
  components: { ModalEdit, },
  props: {
    provider: { type: Object, default: () => ({}), },
    loading: { type: Boolean, default: false, },
  },
  data() {
    return {
      categories: [],
      columns: [
        { name: 'name', title: 'Имя', },
        { name: 'email', title: 'Почта', },
        { name: 'phone', title: 'Телефон', },
        { name: 'tools', title: '', width: '100px', },
      ],
    }
  },
  computed: {
    info() {
      return [
        { title: 'Наименование', value: this.provider.title, },
        { title: 'Категории', value: $n.map(this.provider.categories, (item) => item.title).join(', '), },
      ]
    },
  },
  watch: {
    provider() {
      this.init()
    },
  },
  created() {
    this.init()
    this.$form.rules({
      title: [ 'required', ],
      categories: [ 'required', ],
    })
    this.loadCategories()
  },
  methods: {
    init() {
      this.$form.init({
        title: this.provider.title,
        categories: this.provider.categories,
      })
    },
    loadCategories() {
      this.$var('load', true)
      $api.app.categories.get().all().then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          ...this.$form.get(),
          categories: this.$form.get('categories').map((item) => item.id),
        }
        $api.app.providers.edit(this.provider.id, data).then(() => {
          this.$emit('reload')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    remove(id) {
      this.$var('load', true)
      $api.app.contacts.delete(id).then(() => {
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-info {
  position: relative;
  padding-top: 20px;
  .provider-modal {
    --n-modal-width: 600px;
  }
  table {
    width: 100%;
    .table-headers {
      width: 20%;
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
  }
  td {
    padding: 10px 15px;
    border-bottom: 2px solid #f6f6f6;

    &:first-child {
      width: 20%;
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
  }

  .header {
    display: flex;
    align-items: center;
    .edit-button {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      & button {
        margin-left: 20px;
      }
    }
  }

  .n-card {
    margin: 40px 0 0;
    --n-card-padding: 20px;
  }
  .plus-button {
    font-size: .8rem;
    margin-left: 5px;
  }
  .tools {
    font-size: .8em;
    *:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
