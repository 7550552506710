<template>
  <n-modal class="load-excel" :loading="$var('load')" @close="$emit('close')">
    <a :href="$config('api.servers.default')+'v1/products/template'" download="Шаблон.xlsx">Скачать Excel Шаблон</a>

    <n-form @submit="submit">
      <div class="load-file">
        <n-upload :value.sync="file" />
        <n-icon icon="download" />
        <div class="label">Выберите файл</div>
      </div>

      <div class="footer-buttons">
        <n-button @close="$emit('close')">Отмена</n-button>
        <n-button color="success" type="submit">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'LoadExcel',
  props: {
    orderId: { type: Number, default: 0, },
  },
  data() {
    return {
      file: null,
    }
  },
  created() {
    this.$form.init({
      file: null,
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      $api.files.create({ file: this.file, }).then((response) => {
        const data = { file: response.data.content.id, applicationId: this.orderId, }
        $api.app.products.loadFromExcel(data).then((response) => {
          this.$emit('close')
          this.$emit('reload')
        }).finally(() => {
          this.$var('load', false)
        })
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.load-excel {

  a {
    color: inherit;
    opacity: .7;
  }
  .load-file {
    width: 100%;
    padding: 30px 0 50px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fafafa;
    margin: 10px 0;
    position: relative;

    .label {
      margin: 10px 0 0;
      opacity: .8;
      font-size: .9em;
    }

    .n-upload::v-deep {
      .n-form-item {
        position: static;
      }
      .n-button {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        opacity: 0;
      }
      .n-files {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        margin: 0;
        z-index: 2;
      }
    }
    &:hover {
      .n-icon {
        opacity: .5;
      }
    }
    .n-icon {
      opacity: .3;
      font-size: 2em;
      transition: opacity .3s;
    }
  }
}
</style>
