<template>
  <div class="c-user-stats">
    <h2 v-if="!headless">Статистика</h2>
    <n-table :data="stats" :columns="columns" />
  </div>
</template>

<script>
export default {
  name: 'CUserStats',
  props: {
    user: { type: Object, default: () => ({ statsAllTime: {}, statsYear: {}, statsMonth: {}, }), },
    headless: { type: Boolean, default: false, },
  },
  data() {
    return {
      columns: [
        { name: 'period', title: 'Период', },
        { name: 'activeCount', title: 'Заявки в работе', },
        { name: 'sentCount', title: 'Количество отправленных КП', },
        { name: 'finishedCount', title: 'Количество полученных РО', },
        { name: 'applicationsCount', title: 'Всего заявок', },
      ],
    }
  },
  computed: {
    stats() {
      const titles = { statsAllTime: 'За все время', statsYear: 'За год', statsMonth: 'За месяц', }
      return $n.reduce(titles, (result, title, type) => {
        result.push({
          period: title,
          ...this.user[type],
        })
        return result
      }, [])
    },
  },
}
</script>

<style lang="scss" scoped>
.c-user-stats {
  margin: 15px 0;
  max-width: 800px;
}
</style>
