<template>
  <div class="block-user">
    <n-card :loading="$var('load')" style="max-width: 800px">
      <c-block-title title="Информация">
        <n-button icon="pen" round @click="$var('user', true)" />
      </c-block-title>
      <c-info-table :data="userInfo" />
      <c-user-stats :user="user" />
    </n-card>
    <n-card :loading="$var('loadData')" style="max-width: 800px">
      <c-block-title title="Почтовые ящики пользователя">
        <n-button icon="plus" round @click="$var('email', true)" />
      </c-block-title>
      <n-table :data="emails" :columns="columns">
        <template #imap="{item}">{{ item.imap }}:{{ item.imapPort }}</template>
        <template #smtp="{item}">{{ item.smtp }}:{{ item.smtpPort }}</template>
        <template #tools="{item}">
          <n-button icon="minus" round @click="detachEmail(item)" />
        </template>
      </n-table>
    </n-card>

    <modal-edit v-if="$var('user')" :user="user" @reload="load" @close="$var('user', false)" />
    <modal-attach-email v-if="$var('email')" :user="user" @reload="loadData" @close="$var('email', false)" />
  </div>
</template>

<script>
import ModalEdit from './ModalEdit'
import ModalAttachEmail from './ModalAttachEmail'

export default {
  name: 'BlockUser',
  components: { ModalAttachEmail, ModalEdit, },
  data: () => ({
    user: { stats: {}, },
    emails: [],
    roles: [],
    columns: [
      { name: 'email', title: 'Почта', },
      { name: 'imap', title: 'IMAP', },
      { name: 'smtp', title: 'SMTP', },
      { name: 'from', title: 'От кого', },
      { name: 'tools', title: '', },
    ],
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    userInfo() {
      return [
        { title: 'ФИО', value: this.user.fullName, },
        { title: 'Логин', value: this.user.username, },
        { title: 'Почта для связи', value: this.user.email, },
        { title: 'Номер телефона', value: this.user.phone, },
        { title: 'Роль', value: this.user.role?.title, },
      ]
    },
  },
  created() {
    this.load()
    this.loadData()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.auth.users.get(this.id).with('statsAllTime').with('statsYear').with('statsMonth').with('emails').with('role').then((response) => {
        this.user = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadData() {
      this.$var('loadData', true)
      const apis = [
        $api.auth.users.emails(this.id),
      ]
      Promise.all(apis).then((response) => {
        this.emails = response[0].data.content
      }).finally(() => {
        this.$var('loadData', false)
      })
    },
    remove() {
      this.$var('load', true)
      $api.auth.users.delete(this.id).then((response) => {
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
    detachEmail(email) {
      this.$var('loadData', true)
      $api.email.emails.detach(this.id, email.id).then((response) => {
        this.loadData()
      }).catch(() => {
        this.$var('loadData', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .block-user {
    .edit-button {
      margin-bottom: 20px;
    }
    table {
      width: 100%;
      .table-headers {
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
      .tools {
        display: flex;
        justify-content: end;
        .delete-btn {
          margin-right: 20px;
        }
      }
    }
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;
    }
    .create-container {
      margin: 20px 0 20px 0;
      display: flex;
      align-items: center;
    }
    .form-container {
      text-align: center;
      .action-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
    --n-image-width: 50px;
    --n-image-height: 50px;
  }
</style>
