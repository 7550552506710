<template>
  <div class="block-info">
    <div class="main-info">
      <h3>Основная информация</h3>
      <div class="tools">
        <n-button v-if="editable" icon="pen" flat @click="$var('edit', true)" />
      </div>
      <table class="table-sums">
        <tr>
          <td>Сумма без НДС</td>
          <td>{{ $n.numberFormat(order.total) || '-' }} {{ order.currency }}</td>
        </tr>
        <tr>
          <td>Сумма без НДС со скидкой ({{ order.discount }}%)</td>
          <td>{{ $n.numberFormat(withDiscount) || '-' }} {{ order.currency }}</td>
        </tr>
        <tr>
          <td>НДС ({{ order.nds }}%)</td>
          <td>{{ $n.numberFormat(nds) || '-' }} {{ order.currency }}</td>
        </tr>
        <tr>
          <td>Итого с НДС</td>
          <td>{{ $n.numberFormat(withNds) || '-' }} {{ order.currency }}</td>
        </tr>
      </table>
      <div class="comment">
        <n-textarea title="Комментарий" simple :value="order.comment || '-'" text />
      </div>
    </div>

    <block-fields v-if="order.id" :editable="editable" :order="order" />

    <n-modal v-if="$var('edit')" :loading="$var('load')" @close="$var('edit', false)">
      <n-form @submit="save">
        <n-items>
          <n-input title="Скадка клиенту (в %)" v-bind="$form.input('discount')" />
          <n-input title="НДС (в %)" v-bind="$form.input('nds')" />
          <n-textarea title="Комментарий к КП" v-bind="$form.input('comment')" />
          <n-button type="submit" wide>Сохранить</n-button>
        </n-items>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import BlockFields from './BlockFields'

export default {
  name: 'BlockInfo',
  components: { BlockFields, },
  props: {
    order: { type: Object, default: () => ({}), },
    editable: { type: Boolean, default: false, },
  },
  computed: {
    withDiscount() {
      return this.order.total * (1 - (this.order.discount / 100))
    },
    nds() {
      return this.withDiscount * (this.order.nds / 100)
    },
    withNds() {
      return this.withDiscount + this.nds
    },
  },
  watch: {
    order() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.order) {
        this.$form.init({
          discount: this.order.discount,
          nds: this.order.nds,
          comment: this.order.comment,
        })
      }
    },
    save() {
      this.$var('load', true)
      $api.app.orders.edit(this.order.id, this.$form.get()).then((response) => {
        this.$var('edit', false)
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block-info {
  --n-modal-width: 800px;
  .main-info, .other-info {
    position: relative;
  }
  h3 {
    margin: 20px 0 10px;
  }
  .comment {
    margin-top: 10px;
    &::v-deep p { margin: 2px 0; }
  }
  .n-textarea {
    font-size: .9em;
    margin-bottom: 10px;
  }
  .tools {
    position: absolute;
    top: 0;
    right: 0;
  }
  table {
    width: 100%;
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;

      &:first-child {
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
    }
  }
  .table-sums {
    white-space: nowrap;
    td:last-child {
      width: 100%;
    }
  }
}
</style>
