<template>
  <n-modal class="modal-attach-email" :loading="$var('load')" @close="$emit('close')">
    <h2>Прикрепить почтовый ящик к пользователю</h2>
    <n-form @submit="submit">
      <n-items>
        <n-select title="Email" :data="emails" v-bind="$form.input('email', 'select')" option-title="email" selected-title="email" />
      </n-items>
      <div class="footer-buttons">
        <n-button @click="$emit('close')">Отмена</n-button>
        <n-button type="submit" color="success">Сохранить</n-button>
      </div>
    </n-form>
  </n-modal>
</template>

<script>
export default {
  name: 'ModalAttachEmail',
  props: {
    user: { type: Object, required: true, },
  },
  data() {
    return {
      emails: [],
    }
  },
  created() {
    this.load()
    this.$form.init({
      email: null,
    })
    this.$form.rules({
      email: [ 'required', ],
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.email.emails.get().then((response) => {
        this.emails = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.email.emails.attach(this.user.id, this.$form.get('email.id')).then(() => {
          this.$emit('reload')
          this.$emit('close')
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-attach-email {
  --n-modal-width: 600px;
}
</style>
