<template>
  <div class="c-block-title">
    <h2>{{ title }}</h2>
    <div class="tools">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CBlockTitle',
  props: {
    title: { type: String, required: true, },
  },
}
</script>

<style lang="scss" scoped>
.c-block-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 10px 0 10px;
  h2 {
    margin: 0;
  }
  .tools {
    text-align: right;
  }
}
</style>
