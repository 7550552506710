<template>
  <div class="page-users-list">
    <n-card :loading="$var('load')">
      <div class="table-tools">
        <n-divide>
          <div></div>
          <div><n-button @click="$var('create', true)">Новый пользователь</n-button></div>
        </n-divide>
      </div>
      <n-table :data="users" :columns="columns">
        <template #tools="{item}">
          <n-link :to="{name: 'users.index', params: { id: item.id, }}"><n-button icon="pen" round /></n-link>
        </template>
      </n-table>
    </n-card>

    <modal-users-create v-if="$var('create')" @reload="loadManagers" @close="$var('create', false)" />
  </div>
</template>

<script>
import ModalUsersCreate from './Create'

export default {
  name: 'PageUsersList',
  components: { ModalUsersCreate, },
  data: () => ({
    users: [],
    columns: [
      { name: 'fullName', title: 'ФИО', },
      { name: 'role.title', title: 'Роль', },
      { name: 'statsMonth.activeCount', title: 'Заявки в работе', },
      { name: 'statsMonth.sentCount', title: 'Количество отправленных КП', },
      { name: 'statsMonth.finishedCount', title: 'Количество полученных РО', },
      { name: 'statsMonth.applicationsCount', title: 'Всего заявок', },
      { name: 'tools', title: '', },
    ],
  }),
  mounted() {
    this.loadManagers()
  },
  methods: {
    loadManagers() {
      this.$var('load', true)
      $api.auth.users.get().with('statsMonth').with('role').all().then((response) => {
        this.users = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-users-list {

}
</style>
