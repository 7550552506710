<template>
  <div class="tab-info">
    <n-loader :loading="$var('load')" />

    <c-info-table :data="info">
      <template #image>
        <img v-if="product.image" class="product-image" :src="product.image" alt="image" />
        <span v-else>-</span>
      </template>
    </c-info-table>

    <h2>История заявок</h2>
    <n-table :data="product.calculations" :columns="columns">
      <template #number="{item}">
        <n-link :to="{name: 'orders.index', params: {id: product.application.id}, query: {page: 'products', 'order-product': product.id, tab: 'calc' }}">
          {{ product.application.number }}
        </n-link>
      </template>
      <template #clientCost="{item}">
        {{ $n.numberFormat(item.pieceCostCustomer) }} {{ item.commercialCurrency }}
      </template>
      <template #photo="{item}">
        <img v-if="item.priceOffer.image" style="max-height: 50px" :src="item.priceOffer.image" alt="image" />
        <span v-else></span>
      </template>
      <template #providerCost="{item}">
        {{ $n.numberFormat(item.priceOffer.price) }} {{ item.priceOffer.currency }}
      </template>
    </n-table>
  </div>
</template>

<script>
export default {
  name: 'TabInfo',
  props: {
    product: { type: Object, required: true, },
  },
  data() {
    return {
      columns: [
        { name: 'number', title: 'Заявка', },
        { name: 'clientCost', title: 'Цена для заказчика', },
        { name: 'photo', title: 'Фото поставщика', },
        { name: 'priceOffer.supplier.title', title: 'Поставщик', },
        { name: 'providerCost', title: 'Цена поставщика', },
      ],
    }
  },
  computed: {
    info() {
      return [
        { title: 'Фото', name: 'image', },
        { title: 'Название товара', value: this.product.title, },
        { title: 'Stock Code', value: this.product.code, },
        { title: 'Описание', value: this.product.description, },
        { title: 'Категория', value: this.product.category?.title, },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-info {
  margin-top: 20px;
  .product-image {
    max-height: 80px;
  }
}
</style>
