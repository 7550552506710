<template>
  <div class="tab-product-calc">
    <n-loader :loading="($var('load') || $var('loadData')) && !loading" />
    <price-offers-table :order-id="order.id" :product="product" with-calc :calculation="calculation" :editable="editable" @update:calculation="save" @reload="load" />

    <block-calc :order="order" :calculation="calculation" :currencies="currencies" :editable="editable" @update:calculation="save" @close="$emit('close')" />
  </div>
</template>

<script>
import BlockCalc from './BlockCalc'
import PriceOffersTable from './PriceOffersTable'

export default {
  name: 'TabProductCalc',
  components: { PriceOffersTable, BlockCalc, },
  props: {
    order: { type: Object, default: () => ({}), },
    product: { type: Object, default: () => ({}), },
    loading: { type: Boolean, default: false, },
    editable: { type: Boolean, default: false, },
  },
  data() {
    return {
      offers: [],
      calculation: {},
      currencies: [],
      columns: [
        { name: 'radio', title: '', },
        { name: 'supplier.title', title: 'Поставщик', },
        { name: 'price', title: 'Цена', },
        { name: 'image', title: 'Фото', },
        { name: 'title', title: 'Название', },
        { name: 'weight', title: 'Габариты и вес товара', },
        { name: 'availability', title: 'Наличие / Срок доставки', },
        { name: 'brand', title: 'Производитель', },
        { name: 'shippingAddress', title: 'Адрес отгрузки', },
        { name: 'tools', title: '', },
      ],
    }
  },
  watch: {
    product() {
      this.load()
    },
  },
  created() {
    this.load()
    this.loadData()
    this.offerId = this.$route.query.offer
  },
  methods: {
    load() {
      if (this.product.id) {
        this.$var('load', true)
        $api.app.products.get(this.product.id).with('calculation', (q) => q.with('priceOffer')).then((response) => {
          this.calculation = response.data.content.calculation || {}
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    loadData() {
      this.$var('loadData', true)
      $api.app.currencies.get().then((response) => {
        this.currencies = response.data.content
      }).finally(() => {
        this.$var('loadData', false)
      })
    },
    save(value) {
      this.$var('load', true)
      $api.app.products.calc(this.product.id, value).then((response) => {
        this.calculation = response.data.content
        this.$emit('reload')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-product-calc {
  position: relative;
  padding-top: 20px;
  margin-top: -1px;
}
</style>
