<template>
  <div class="n-datepicker">
    <input ref="input" />
    <n-input v-bind="bind" v-on="$listeners" @click="s_click" />
  </div>
</template>

<script>
import datepicker from 'flatpickr'
import { Russian, } from 'flatpickr/dist/l10n/ru.js'
import props from 'nast-ui/components/Input/props'

export default {
  name: 'NDatepicker',
  mixins: [ props, ],
  props: {
    timeOnly: { type: Boolean, default: false, },
    time: { type: Boolean, default: false, },
    mask: { type: Object, default: undefined, },
    maskStatus: {
      type: Function, default: () => {
      },
    },
  },
  data: () => ({
    picker: null,
  }),
  computed: {
    format() {
      return this.timeOnly ? 'time' : (this.time ? 'datetime' : 'date')
    },
    bind() {
      return {
        ...this.props,
        ...this.events,
        mask: this.mask,
        maskStatus: this.maskStatus,
        value: this.value ? $app.date.format(this.value, this.format) : '',
      }
    },
  },
  watch: {
    value() {
      this.picker.setDate(this.value)
    },
  },
  mounted() {
    this.picker = datepicker(this.$refs.input, {
      locale: Russian,
      disableMobile: true,
      dateFormat: 'Z',
      enableTime: this.time || this.timeOnly,
      noCalendar: this.timeOnly,
      onChange: (selectedDates, dateStr, instance) => {
        this.$emit('input', dateStr)
        this['input'](dateStr)
        this.$emit('update:value', dateStr)
        this['update:value'](dateStr)
      },
    })
    this.picker.setDate(this.value)
  },
  methods: {
    s_click() {
      this.picker.open()
    },
  },
}
</script>

<style lang="css" src="flatpickr/dist/flatpickr.min.css"></style>
<style lang="scss">
html {
  --n-datepicker-bg: #fff;
  --n-datepicker-bg-head: #eaedf5;
  --n-datepicker-color: #555;
}
.n-datepicker {
  display: block;
  width: 100%;

  & > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
</style>
<style lang="scss">
  .flatpickr-calendar {
    @media screen and (max-width: 600px) {
      position: fixed;
      top: auto !important;
      left: auto !important;
      bottom: 0 !important;
      width: 100% !important;
      right: auto !important;
      &.arrowTop:before, .arrowTop:after {
        display: none;
      }
      .flatpickr-innerContainer {
        justify-content: center;
      }
    }

    background: var(--n-datepicker-bg);
    box-shadow: 1px 0 0 var(--shadow-color),
      -1px 0 0 var(--shadow-color),
      0 1px 0 var(--shadow-color),
      0 -1px 0 var(--shadow-color),
      0 3px 13px rgb(0 0 0 / 8%);

    .flatpickr-months {
      padding: 8px 0;
      background: var(--n-datepicker-bg-head);

      .flatpickr-prev-month, .flatpickr-next-month {
        top: 4px;
      }

      .flatpickr-monthDropdown-months {
        margin-right: 10px;
      }

      .flatpickr-monthDropdown-months, .numInput.cur-year {
        color: var(--primary);
        font-weight: 600;
        font-size: 14px;
      }

      .flatpickr-current-month {
        left: 16.5%;
      }

      svg {
        padding: 3px;
        width: 20px;
        height: 20px;
        background: var(--primary);
        border-radius: 100%;
        fill: white;
      }

      span:hover svg {
        fill: white;
      }
    }

    .flatpickr-weekdays {
      padding: 10px 0 5px;
    }

    .flatpickr-weekday {
      font-size: 14px;
      color: var(--primary);
      font-weight: 600;
    }

    .flatpickr-day {
      font-size: 14px;
      font-weight: 600;
      border-radius: 5px;
      height: 28px;
      margin: 5px 0;
      line-height: 26px;
      color: var(--n-datepicker-color);

      &.selected {
        color: var(--primary-text) !important;
        background: var(--primary) !important;
      }

      &.today {
        background: var(--n-datepicker-bg-head);
        border: none;

        &:hover, &:focus {
          color: inherit !important;
        }
      }
      &.nextMonthDay, &.prevMonthDay {
        opacity: .3;
      }
    }

    .flatpickr-time input {
      color: var(--n-datepicker-color);
      &:hover, &:focus {
        opacity: 1;
        background: var(--n-datepicker-bg-head);
      }
    }
  }
</style>
